<template>
  <div class="card">
    <div class="card-header border-1 d-flex justify-content-between align-items-center">
      <h6 class="mb-md-0">Lista de Usuarios</h6>
    </div>
    <!-- Agrega un campo de entrada para el buscador -->
    <div class="pb-0 card-header mb-0">
      <input v-model="search" type="text" class="form-control mt-2" placeholder="Buscar...">
    </div>
    <div class="card-body table-responsive">
      <table class="table align-items-center mb-0">
        <thead>
          <tr>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Nombre
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              Créditos cuenta
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              Activo
            </th>
            <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              Administrador
            </th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
              Acción
            </th>
          </tr>
        </thead>
        <tbody>
        <tr v-for="(row, index) in $store.state.datosPaginados" :key="index">
            <td>
              <div class="d-flex px-2 py-1">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm">{{row.shortName}}</h6>
                </div>
              </div>
            </td>
            <td>
              <p class="text-sm text-secondary mb-0">{{ row.remainingSms }}</p>
            </td>
            <td>
              <span class="badge badge-dot me-4">
                <i :class="{ 'bg-info': row.active, 'bg-dark': !row.active }"></i>
                <span class="text-dark text-xs">{{row.active ? "Activo" : "No activo"}}</span>
              </span>
            </td>
          <td>
            <p class="text-sm text-secondary mb-0">
               <span class="badge badge-dot me-4">
                <i :class="{ 'bg-info': row.adminUser, 'bg-dark': !row.adminUser }"></i>
                <span class="text-dark text-xs">{{row.adminUser ? "Si" : "No"}}</span>
              </span>
            </p>
          </td>
          <td class="align-middle text-center text-sm">
            <a class="btn btn-info me-2 mb-0" v-show="permisos.modify" href="#!"
               data-toggle="tooltip" data-placement="top" title="Editar Usuario"
               @click.prevent="$parent.openModalEditarUser(row)">
              <i class="fas fa-edit"></i>
            </a>
            <a class="btn btn-danger me-2 mb-0" v-show="permisos.destroy" href="#!"
               data-toggle="tooltip" data-placement="top" title="Eliminar Usuario"
               @click.prevent="$parent.openModalEliminarUser(row)">
              <i class="fas fa-trash"></i>
            </a>
<!--            <a class="btn btn-danger me-2 mb-0" v-show="permisos.destroy" href="#!" data-toggle="tooltip" data-original-title="Eliminar usuario"
               @click.prevent="$parent.openModalEliminarUser(row)">
              <i class="fas fa-trash"></i>
            </a>
            <a class="btn btn-warning me-2 mb-0" v-show="permisos.modify" href="#!" data-toggle="tooltip" data-original-title="Permisos"
               @click.prevent="$parent.openModalPermisosUser(row)">
              <i class="fas fa-user-check"></i>
            </a>
            <a class="btn btn-success mb-0" v-show="permisos.modify" href="#!" data-toggle="tooltip" data-original-title="Login"
               @click.prevent="login_sub_user(row)">
              <i class="fas fa-sign-in-alt"></i>
            </a>-->
          </td>
          </tr>
        </tbody>
      </table>
    </div>
    <BasePagination :filtered-data="filteredData" />
  </div>
</template>

<script>

import customer from "../UserAdmin.vue";
import {onPermissionsUser} from "@/config/servicios/campana/util";
import BasePagination from "@/components/BasePagination.vue";
export default {
  name: "UserAdminTable",
  components: {
    BasePagination,
  },
  props: {
    userData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      permisos: {
        create: onPermissionsUser('users.xhtml', 'create'),
        modify: onPermissionsUser('users.xhtml', 'modify'),
        destroy: onPermissionsUser('users.xhtml', 'destroy'),
      },
      search: "", // Variable para almacenar la entrada del buscador
    };
  },
  computed: {
    customer() {
      return customer
    },
    // Utiliza una propiedad computada para filtrar los datos según la búsqueda
    filteredData() {
      if (!Array.isArray(this.userData)) {
        return [];
      }
      //this.$store.getters.getDataPaginated(this.$store.state.paginaActual, this.filteredData);
      return this.userData.filter(customer => {
        const name = customer.shortName ?? '';
        return (
            name.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
  },
  watch: {
    search() {
      this.$store.getters.getDataPaginated(this.$store.state.paginaActual, this.filteredData);
    }
  },
  mounted() {
    this.$store.getters.getDataPaginated(this.$store.state.paginaActual, this.filteredData);
  },
  methods: {
  },
};
</script>
<style scoped>
</style>
