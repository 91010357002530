<template>
  <div class="card">
    <div class="card-header border-1 d-flex justify-content-between align-items-center flex-column flex-md-row">
      <h6 class="mb-md-0">Remitentes Email</h6>
      <div class="d-flex">
        <button
            class="btn btn-celcom-orange m-0"
            data-toggle="tooltip"
            data-original-title="Agregar Contacto"
            @click.prevent="agregar"
        >
          <i class="fas fa-plus"></i> Agregar
        </button>
      </div>
    </div>
    <!-- Agrega un campo de entrada para el buscador -->
    <div class="pb-0 card-body mb-0">
      <input v-model="search" type="text" class="form-control mt-2" placeholder="Buscar...">
    </div>
    <div class="card-body table-responsive">
      <table class="table align-items-center mb-0">
        <thead>
          <tr>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Nombre de ...
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              Correo de ...
            </th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
              Acción
            </th>
          </tr>
        </thead>
        <tbody>
        <tr v-for="(row, index) in filteredData" :key="index">
            <td>
              <div class="d-flex px-2 py-1">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm">{{row.fromName}}</h6>
                </div>
              </div>
            </td>
            <td>
              <p class="mb-0 text-sm">{{ row.emailFrom }}</p>
            </td>
          <td class="align-middle text-center text-sm">
            <a class="btn btn-info m-0 me-2" href="#!" data-toggle="tooltip" data-original-title="Permisos"
               @click.prevent="$parent.openModalEditSender(row)">
              <i class="fas fa-edit"></i>
            </a>
            <a class="btn btn-danger m-0" href="#!" data-toggle="tooltip" data-original-title="Eliminar sender"
               @click.prevent="$parent.openModalEliminarSender(row)">
              <i class="fas fa-trash"></i>
            </a>
          </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>

export default {
  name: "SenderEmailCampannaTable",
  components: {

  },
  props: {
    senderEmailData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      search: "", // Variable para almacenar la entrada del buscador
    };
  },
  computed: {
    // Utiliza una propiedad computada para filtrar los datos según la búsqueda
    filteredData() {
      if (!Array.isArray(this.senderEmailData)) {
        return [];
      }
      return this.senderEmailData.filter(sender => {
        const fromName = sender.fromName ?? '';
        const emailFrom = sender.emailFrom ?? '';

        return (
            fromName.toLowerCase().includes(this.search.toLowerCase()) ||
            emailFrom.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
  },
  methods: {
    agregar(){
      this.$parent.openModalAddSender();
    },
  },
};
</script>
