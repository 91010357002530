// api.js
import axios from 'axios';
import Config from "@/config/servicios/campana/config";
export const obtenerLista = (body) => {
    return axios.post(Config.url_service + Config.sending_sms_available,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};

export const deleteProgramado = (body) => {
    return axios.post(Config.url_service + Config.sending_sms_abort,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};

/*export const addEditProgramado = (body) => {
    return axios.post(Config.url_service + Config.sending_sms_create_edit,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};*/
export const sending_sms_create_edit = (body) => {
    return axios.post(Config.url_service + Config.sending_sms_create_edit,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const smsGetProgramado = (body) => {
    return axios.post(Config.url_service+Config.sending_sms_get,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};

export const contact_availableProgramado = (body) => {
    return axios.post(Config.url_service+Config.contact_available,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};

export const draft_availableProgramado = (body) => {
    return axios.post(Config.url_service+Config.draft_available,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};

export const landing_availableProgramado = (body) => {
    return axios.post(Config.url_service+Config.landing_available,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};

export const contact_available_contact_preview = (body) => {
    return axios.post(Config.url_service+Config.contact_available_contact_preview,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const udh_validate_credits = (body) => {
    return axios.post(Config.url_service+Config.udh_validate_credits,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
/*export const sending_sms_send = (body) => {
    return axios.post(Config.url_service+Config.sending_sms_send,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};*/
export const sending_sms_send = (body) => {
    return axios.post(Config.url_service+Config.sending_sms_send,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};

// Inicio Modulos Envios Whatsapp
export const sending_whatsapp_send = (body) => {
    return axios.post(Config.url_service+Config.sending_whatsapp_send,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const sending_whatsapp_create_edit = (body) => {
    return axios.post(Config.url_service+Config.sending_whatsapp_create_edit,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
// Fin Modulos Envios Whatsapp

// Inicio Modulos Envios Email
export const sending_email_create_edit = (body) => {
    return axios.post(Config.url_service + Config.sending_email_create_edit,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const sending_email_send = (body) => {
    return axios.post(Config.url_service+Config.sending_email_send,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
// Fin Modulos Envios Email
// Agrega más funciones según tus necesidades