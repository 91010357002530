<template>
  <div
      class="card multisteps-form__panel border-radius-xl bg-white"
      data-animation="FadeIn"
  >
    <div class="card-header border-1">
      <h5 class="font-weight-bolder m-0">Mensaje</h5>
    </div>
    <div class="card-body multisteps-form__content">
      <div class="col-12 col-sm-6">
        <div class="row ms-1">
          <div class="form-check col-md-6">
            <input
                id="redactar"
                v-model="switchValueMen"
                type="radio"
                name="redactar"
                value="redactar"
                class="form-check-input"
                @change="limpiar"
            />
            <label for="redactar">Redactar</label>
          </div>
          <div class="form-check col-md-6">
            <input
                id="plantilla"
                v-model="switchValueMen"
                type="radio"
                name="plantilla"
                value="plantilla"
                class="form-check-input"
                @change="limpiar"
            />
            <label for="plantilla">Plantillas</label>
          </div>
        </div>
<!--        <argon-switch
            id="tipo-mensaje"
            v-model="switchValueMen"
            name="tipo-mensaje"
            class="form-check form-switch mb-0 ms-2"
            @change="onSwitchChange"
        />
        <label class="mb-0">{{switchValueMen ? "Plantillas" : "Redactar"}}</label>-->
      </div>
      <hr>
      <div v-if="switchValueMen === 'redactar'">
      <div class="row">
          <div class="col-12 col-sm-6">
            <label>Asunto
              <span v-if="contHeader === 0"
                    type="button"
                    class="badge badge-celcom-orange"
                    title="Haz clic para agregar una variable"
                    @click="addVariable">
                    Agregar nuevo <i class="fas fa-plus fa-xl"></i>
              </span>
            </label>
            <argon-input
                id="textoCabeceraEmail"
                v-model="textoCabecera"
                class="multisteps-form__input m-0"
                type="text"
                placeholder="Asunto"
            />
            <div class="text-end mb-2">
              <Emoji @emoji-selected="handleEmojiSelected"/>
            </div>
          </div>
          <div v-if="asunto" class="col-12 col-sm-6">
            <label>Elija Variable</label>
            <select
                id="selectCab2"
                v-model="selectedCabsubject"
                class="multisteps-form__select form-control form-select"
                @change="putFieldSubject"
            >
              <option selected="selected" disabled value="">Variable</option>
              <option v-for="(cab, cabIndex) in $store.state.responseCustomer"
                      :key="cabIndex"
                      :value="cab"
              >{{ cab.name }}</option>
            </select>
          </div>
        </div>
        <QuillEditor
            ref="quillEditor"
            v-model:content="editorContent"
            content-type="html"
            toolbar="full"
        />
        <div class="text-end mb-2">
          <Emoji :value-body="valueBody" @emoji-selected-body="handleEmojiSelectedBody"/>
        </div>
        <div class="col-12 form-check">
          <input
              v-model="activeIdSeguimiento"
              class="form-check-input"
              type="checkbox"
              id="estadoCheckbox"
              @change="putFieldUrl"
          />
          <label class="form-check-label" for="estadoCheckbox">
            Usar Id de Seguimiento
          </label>
        </div>
        <div v-if="activeIdSeguimiento" class="row">
          <label>Id de Seguimiento</label>
          <div class="col-sm-6">
            <select
                id="selectIdSeguimiento"
                v-model="selectedIdSeguimiento"
                class="multisteps-form__select form-control form-select"
                :disabled="idSeguimientos.length > 1"
                @change="agregarIdSeguimiento"
            >
              <option value="" disabled selected>Ids</option>
              <option v-for="(cab, cabIndex) in $store.state.responseCustomer"
                      :key="cabIndex"
                      :value="cab"
              >{{ cab.name }}</option>
            </select>
          </div>
          <div class="col-sm-6">
              <span
                  v-for="id in idSeguimientos"
                  :key="id.name" class="badge badge-celcom-orange me-1 clickable-badge"
                  @click="quitarIdSeguimiento(id)"
              > {{ id.name }} </span>
          </div>
        </div>
        <br>
        <div class="col-12 col-sm-5 mt-2">
          <label>Variables</label>
          <select
              id="selectCab"
              v-model="selectedCab"
              class="multisteps-form__select form-control form-select"
              @change="putFieldMessage"
          >
            <option value="" disabled selected hidden>Seleccione Variable</option>
            <option v-for="(cab, cabIndex) in $store.state.responseCustomer"
                    :key="cabIndex"
                    :value="cab"
            >{{ cab.name }}</option>
          </select>
        </div>
      </div>
      <div v-if="switchValueMen === 'plantilla'" class="row mt-3">
        <label>Seleccionar Plantilla</label>
        <div class="">
          <select
              id="opcionesSelectDraft"
              v-model="selectedselectDraftValue"
              class="multisteps-form__select form-control form-select"
              name="opciones-ListCont"
              @change="addToselectedselectDraft"
          >
            <option value="" disabled selected>Plantillas</option>
            <option v-for="option in $store.state.responsePlantillasEmail" :key="option.draftId" :value="option">{{option.name}}</option>
          </select>
        </div>
        <div class="col-12 mt-3">
          <card type="frame" v-for="option in selectedselectDraft"
                :key="option.draftId">
            <h5>Plantilla : {{option.name}}</h5>
            <div class="plantilla-msg" v-html="option.message"></div>
          </card>
        </div>
      </div>
    </div>
    <div class="card-footer border-1 mt-4 d-flex">
      <argon-button
        type="button"
        color="light"
        variant="gradient"
        class="js-btn-prev"
        @click="$parent.prevStep"
      ><i class="fas fa-chevron-left me-2"></i> Anterior
      </argon-button>
      <argon-button
        type="button"
        color="celcom"
        variant="gradient"
        class="text-white ms-auto mb-0 js-btn-next"
        @click="nextStepValor"
      >Siguiente <i class="fas fa-chevron-right ms-2"></i>
      </argon-button>
    </div>
  </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
import Choices from "choices.js";
import {validarRut, validarVacio} from "@/config/validaciones";
import ArgonInput from "@/components/ArgonInput.vue";
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import Emoji from "@/views/envios/whatsapp/Add/components/Emoji.vue";
import card from "@/views/envios/components/Card.vue";
export default {
  name: "ProgramadaEditEmailAdd2",
  components: {
    card,
    ArgonInput,
    ArgonButton,
    QuillEditor,
    Emoji,
  },
  props: {
    sender: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  emits: ['updateValues2', 'updateValues2Email'],
  data() {
    return {
      selectedIdSeguimiento: '',
      activeIdSeguimiento: false,
      idSeguimientos: [],
      valueBody: true,
      editorContent: '', // Variable para almacenar el contenido del editor Quill
      textoCabecera: '',
      contHeader: 0,
      selectedCabsubject: '',
      selectedCab: '',
      asunto: false,

      selectedUrl: '',
      url_existente: false,
      short_url: "",
      urlValue: "",
      activeUrl: false,
      activeShort_url: false,
      selectedselectDraftValue: null,
      selectedTemplateValue: null,
      selectedPlanCont: "",
      message: '',
      switchValueMen:  "redactar",
      rut: "",
      selectedselectDraft: [],
      selectedselectTemplate: [],
      selectedTag: "", // Nuevo dato para almacenar la opción seleccionada
      tags: "",       // Nuevo dato para almacenar los tags seleccionados
    };
  },
  computed: {
    validarRut() {
      return validarRut(this.rut);
    },
    validarVacios() {
      return validarVacio(this.tags);
    },
  },
  watch: {
    textoCabecera(newValue) {
      if (!this.textoCabecera.includes('{' + this.selectedCabsubject.name + '}')) {
        this.contHeader = 0;
        this.selectedCabsubject = '';
      }
      this.senderHeader = newValue;
    },
  },
  mounted() {
    this.$store.getters.arrayCabeceras();
    if (document.getElementById("opcionesTags")) {
      var element = document.getElementById("opcionesTags");
      new Choices(element, {
        searchEnabled: false,
        allowHTML: true,
      });
    }
  },
  methods: {
    quitarIdSeguimiento(id) {
      this.idSeguimientos = this.idSeguimientos.filter((item) => item !== id);
    },
    agregarIdSeguimiento() {
      this.idSeguimientos.push(this.selectedIdSeguimiento);
    },
    limpiar() {
      this.message = "";
      this.selectedselectDraft = [];
      this.selectedselectTemplate = [];
      this.short_url = "";
      this.urlValue = "";
      this.activeUrl = false;
      this.activeShort_url = false;
      this.selectedCab = "";
      this.selectedselectDraftValue = null;
      this.selectedTemplateValue = null;
      this.selectedTag = "";
      this.tags = "";
    },
    handleEmojiSelected(emoji) {
      this.textoCabecera = this.textoCabecera + emoji;
    },
    handleEmojiSelectedBody(emoji) {
      // Obtener el contenido HTML actual del editor
      var contenidoActual = this.$refs.quillEditor.getHTML();

      // Concatenar el nuevo contenido HTML al contenido actual
      var contenidoFinal = contenidoActual.replace('</p>', '') + emoji + '</p>';

      // Establecer el contenido HTML completo del editor
      this.$refs.quillEditor.setHTML(contenidoFinal);
    },
    addVariable() {
      if (this.contHeader === 0) {
        this.contHeader += 1;
        this.asunto = true;
      }
    },
    putFieldSubject() {
      this.textoCabecera = this.textoCabecera + '{' + this.selectedCabsubject.name + '}';
      this.asunto = false;
    },
    putFieldMessage() {
      // Obtener el contenido HTML actual del editor
      var contenidoActual = this.$refs.quillEditor.getHTML();

      // Obtener el contenido HTML que deseas agregar
      var nuevoContenidoHTML = "{" + this.selectedCab.name + "}";

      // Concatenar el nuevo contenido HTML al contenido actual
      var contenidoFinal = contenidoActual.replace('</p>', '') + nuevoContenidoHTML + '</p>';

      // Establecer el contenido HTML completo del editor
      this.$refs.quillEditor.setHTML(contenidoFinal);

      this.selectedCab = '';
    },
    nextStepValor() {
      // Puedes emitir un evento para notificar al componente padre sobre los cambios
      this.$emit('updateValues2Email', {
        message: this.switchValueMen === 'redactar' ? this.$refs.quillEditor.getHTML() : "",
        subjet: this.textoCabecera,
        selectDraft: this.selectedselectDraft,
        selectTemplate: this.selectedselectTemplate,
        idSeguimiento: this.idSeguimientos,
      });
      this.$parent.nextStep();
    },

    urlExistente() {
      this.url_existente = !this.url_existente;
    },
    getUrlExistente() {
      this.short_url = this.selectedUrl;
    },
    async getShort_url() {
      this.short_url = await this.$store.getters.add_short_urls(this.urlValue);
    },
    putFieldUrl() {
      if (this.activeUrl) {
        this.message += "{URL}";
      } else {
        // Quitar "{URL}" del mensaje
        this.message = this.message.replace("{URL}", "");
      }
    },

    addToselectedselectDraft() {
      // Verificar si el valor ya está en el arreglo antes de agregarlo

      if (this.selectedselectDraft.indexOf(this.selectedselectDraftValue) === -1) {
        this.selectedselectDraft.push(this.selectedselectDraftValue);
      }
      console.log(JSON.stringify(this.selectedselectDraft));
    },
    addToselectedTemplate() {
      // Verificar si el valor ya está en el arreglo antes de agregarlo

      if (this.selectedselectTemplate.indexOf(this.selectedTemplateValue) === -1) {
        this.selectedselectTemplate.push(this.selectedTemplateValue);
      }
      console.log(JSON.stringify(this.selectedTemplateValue));
    },
    onSwitchChange(event) {
      this.switchValueMen = event.target.checked;
    },
    validarVacio,
    updateTags() {
      // Agregar el tag seleccionado al string de tags
      if (this.selectedTag && this.selectedTag !== "..." && !this.tags.includes(this.selectedTag)) {
        if (this.tags.length > 0) {
          this.tags += `, ${this.selectedTag}`;
        } else {
          this.tags = this.selectedTag;
        }
      }
    },
  },
};
</script>
<style>
.clickable-badge {
  cursor: pointer; /* Cambia el cursor a una mano para indicar interactividad */
  transition: background-color 0.3s; /* Suaviza la transición de colores */
}

.clickable-badge:hover {
  background-color: #f8d7da; /* Cambia el color de fondo al pasar el mouse */
}
.plantilla-msg p {
  font-size: 0.875rem;
}
.plantilla-msg img {
  max-width: 100%;
  height: auto;
}
</style>