<template>
    <div class="container-fluid py-5">
      <div class="row">
        <div class="col-10 mx-auto">
          <div class="multisteps-form">
            <div class="card">
              <div class="card-body">
                <div class="multisteps-form__progress">
                  <button
                    class="multisteps-form__progress-btn js-active"
                    type="button"
                    title="Info Basica"
                    :class="activeStep >= 0 ? activeClass : ''"
                    @click="activeStep = 0"
                  >
                    <span>1</span>
                  </button>
                  <button
                    class="multisteps-form__progress-btn"
                    type="button"
                    title="Address"
                    :class="activeStep >= 1 ? activeClass : ''"
                    @click="activeStep = 1"
                  >
                    2
                  </button>
                </div>
              </div>
            </div>
            <!--form panels-->
            <div class="row mt-4">
              <div class="col m-auto">
                <form class="multisteps-form__form">
                  <whatsapp1 :class="activeStep === 0 ? activeClass : ''" />
                  <whatsapp2 :class="activeStep === 1 ? activeClass : ''" />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Whatsapp1 from "./Whatsapp1.vue";
  import Whatsapp2 from "./Whatsapp2.vue";
  import {encryptAES, getUser, getUserSub, horaAhora} from "@/config/servicios/campana/util";
  import {ADD_TEMPLATEWHATSAPP_BY_CUSTOMER} from "@/config/servicios/campana/whatsapp/servicesWhatsapp";

  export default {
    name: "NuevoTemplate",
    components: {
      Whatsapp1,
      Whatsapp2,
    },
    beforeRouteLeave(to, from, next) {
      // Limpiar las variables antes de salir de la vista
      this.$store.commit('RESET_BOTONES');

      next();
    },
    data() {
      return {
        perfil: null,
        showMenu: false,
        activeClass: "js-active position-relative",
        activeStep: 0,
        formSteps: 3,
      };
    },
    mounted() {
      this.perfil = this.$route.query.perfil;
    },
    methods: {
      arregloBotones(){
        let botones = [];
        let botonesArray = this.$store.state.botones;
        for (let i = 0; i < botonesArray.length; i++) {
          botones.push({
            "whatsappButtonId": botonesArray[i].id,
            "buttonName": botonesArray[i].texto,
            "action": botonesArray[i].valorNumerico,
            "buttonType":botonesArray[i].tipo,
          });
        }
        return botones;
      },
      async addPlantillaWhatsapp(messageBodyWithVariables, header, messageFooter, type, datalocation, file) {
        try {
          let user = (getUserSub()!=null) ? getUserSub() :getUser();
          const muestraString = this.$store.state.tomarVariablesMuestras.map(item => item.muestra).join('--');
          const valorString = this.$store.state.tomarVariablesMuestras.map(item => item.valor).join('--');
          let plantillaWhatsappName = document.getElementById("plantillaWhatsappName");
          let selectedCategoriaWhatsapp = document.getElementById("selectedCategoriaWhatsapp");
          let messageBodyWhatsapp = document.getElementById("messageBodyWhatsapp");
          let textoCabeceraWhatsapp = document.getElementById("textoCabeceraWhatsapp");
          let plantillaEtiquetasWhatsapp = document.getElementById("plantillaEtiquetasWhatsapp");

          // Comprueba si los elementos existen y tienen valores válidos
          let data = {
            "whatsappSenderId": null,
            "templateId": "",
            "templateName": plantillaWhatsappName ? plantillaWhatsappName.value : "",
            "category": selectedCategoriaWhatsapp ? selectedCategoriaWhatsapp.value.toUpperCase() : "",
            "body": messageBodyWhatsapp ? messageBodyWhatsapp.value : "",
            "params": valorString,
            "paramsValue": muestraString,
            "templateType": type,
            "headerTemplate": type === 'TEXT' ? (textoCabeceraWhatsapp ? textoCabeceraWhatsapp.value : "") : "",
            "footer": messageFooter,
            "bodyExample": messageBodyWithVariables,
            "exampleHeader": type === 'TEXT' ? header : "",
            "vertical": plantillaEtiquetasWhatsapp ? plantillaEtiquetasWhatsapp.value : "",
            "messageMediaType": datalocation,
            "customerWhatsapp": {
              "customerWhatsappId": this.$store.state.perfil.customerWhatsappId || null,
              "whatsappName": this.$store.state.perfil.whatsappName || "",
              "whatsappSrc": this.$store.state.perfil.whatsappSrc || "",
              "whatsappApp": this.$store.state.perfil.whatsappApp || "",
              "appId": this.$store.state.perfil.appId || null
            },
            "whatsappButtonList": this.arregloBotones() || [],
          };
          console.log(JSON.stringify(data));
          let js = {
            time_expired: horaAhora(),
            customer: encodeURIComponent(JSON.stringify(user.customer)),
            whatsappSender: encodeURIComponent(JSON.stringify(data)),
          };

          /*var body = new URLSearchParams();
          body.append("TOKEN", encryptAES(js));
          body.append("file", encryptAES(file));*/
          var formData = new FormData();
          formData.append("TOKEN", encryptAES(js));
          formData.append("file", file);

          const response = await ADD_TEMPLATEWHATSAPP_BY_CUSTOMER(formData);
          // Almacena la respuesta en la variable de datos
          if (response.data.response === "200") {
            console.log(response.data.data);
            //redireccionar a la lista de plantillas
            this.$router.push({
              name: "Lista Templates"
            });
          } else {
            console.log("No se pudo crear la Plantilla");
          }
        } catch (error) {
          console.error("Error al obtener datos", error);
        }
      },
      nextStep() {
        if (this.activeStep < this.formSteps) {
          this.activeStep += 1;
        } else {
          this.activeStep -= 1;
        }
      },
      prevStep() {
        if (this.activeStep > 0) {
          this.activeStep -= 1;
        }
      },
    },
  };
  </script>
  