<template>
  <div
      class="card multisteps-form__panel border-radius-xl bg-white"
      data-animation="FadeIn"
  >
    <div class="multisteps-form__content">
        <!-- Card body -->
        <div class="card-header border-1 bg-transparent">
          <h5 class="font-weight-bolder m-0">Confirmación del envío</h5>
        </div>
        <div class="card-body">

          <h6 class="font-weight-bolder">Vista previa del envío</h6>
          <p class="small">Se muestra un ejemplo de 10 números.</p>

          <div class="table-responsive">
            <table class="table align-items-center mb-0">
              <thead>
                <tr class="thead-light">
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Número</th>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Mensaje</th>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Caracteres</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in sendingLocal" :key="index">
                  <td class="align-middle text-left text-sm">{{ row.phoneCode + " " + row.phone }}</td>
                  <td class="align-middle text-left text-wrap text-sm">{{ row.message }}</td>
                  <td class="align-middle text-left text-wrap text-sm">{{ row.message.length }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="card-footer border-1">
          <argon-button
              class="mt-0 d-block ml-auto"
              @click.prevent="nextStepValor"
          >
            <i class="fas fa-paper-plane"></i> Enviar
          </argon-button>
        </div>
      </div>
    </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";

export default {
  name: "ProgramadaEditAdd4",
  components: {
    ArgonButton
  },
  props: {
    sendingsms: {
      type: Array,
      default: () => {
        return [];
      },
    },
    sendingwhatsapp: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      sendingLocal: [],
    };
  },
  watch: {
    sendingsms: {
      handler(newVal) {
        this.sendingLocal = newVal ? newVal.sendingSmsDetailList : [];
      },
      immediate: true
    },
    sendingwhatsapp: {
      handler(newVal) {
        this.sendingLocal = newVal ? newVal.sendingSmsDetailList : [];
      },
      immediate: true
    }
  },
  methods: {
    async nextStepValor() {
      if (this.$store.state.seleccion === 'SMS') {
        await this.$parent.envioServices();
      }else if (this.$store.state.seleccion === 'Whatsapp') {
        await this.$parent.envioServicesWhatsapp();
      }
    },
  },

};
</script>
