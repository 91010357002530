import {agreContactos} from "@/config/servicios/contactos/services";
import {decryptAES} from "@/config/servicios/campana/util";

export default {
    url_service: "https://automata2.celcom.cl/flex",
    login: "/api/customer/login",
    triggerTipo: "/api/flow/triggertype/4/list",
    accionDisponible: "/api/action/customer/1/list",
    listaCanales: "/api/channel/admin/1/list",
    obtenerCondicion: "/api/condition/customer/1/list"
}

export const tipoUser = () => {
// Obtener el valor del local storage
    let encryptedToken = localStorage.getItem('user');
    const userDataString = decryptAES(encryptedToken);

// Obtener el valor de customerId
    return userDataString.adminUser;
};
export const tipoSuperUserClient = () => {
// Obtener el valor del local storage
    let encryptedToken = localStorage.getItem('user');
    const userDataString = decryptAES(encryptedToken);

// Obtener el valor de customerId
    return userDataString.customer.superUserClient;
};
export const procesarExcel = (data) => {
    const jsonDataContactos = data.map((fila) => {
        // Aquí debes acceder a cada celda de la fila y asignarla a la propiedad correspondiente
        // Esto es solo un ejemplo; debes adaptarlo según la estructura real de tus datos
        const [name, rut, tag, email, empresa, telefono, birthdate, gender, country, sourceId] = fila;

        // Devuelve un objeto con las propiedades que necesitas
        if (fila[0] !== "Nombre" && fila[0] !== undefined) {
            return {
                name,
                rut,
                email,
                empresa,
                telefono,
                tag,
                birthdate,
                gender,
                country,
                sourceId,
            };
        }
        // Devuelve null si la condición no se cumple
        return null;
    }).filter(Boolean); // Filtra los elementos nulos

    console.log(jsonDataContactos);
    const transformedData = {
        contacts: jsonDataContactos.map(contact => {
            return {
                contactId: null,
                contactName: contact.name,
                taxId: contact.rut,
                customerSegmentId: null,
                customerTag: contact.tag,
                email: contact.email,
                empresa: contact.empresa,
                msisdn: String(contact.telefono), // Convertir a cadena si es necesario
                birthDate: contact.birthdate, // Puedes necesitar una función para convertir la fecha
                gender: contact.gender, // Puedes necesitar una función para convertir el género
                country: contact.country, // Puedes necesitar una función para convertir el país
                sourceId: convertirSourceId(contact.sourceId) // Puedes necesitar una función para convertir el sourceId
            };
        })
    };

    console.log(transformedData);
    return agreContactos(transformedData)
        .then((response) => {
            console.log(response.data);
        })
        .catch((error) => {
            console.error("Error al enviar los datos:", error);
        });
    // Puedes devolver el arreglo si lo necesitas en otro lugar
    //return jsonDataContacto;
};

function convertirSourceId(sourceId) {
    if (sourceId === "Web") {
        return 1;
    }
}

export const formatoFecha = (timestamp) => {
    // Crear un objeto de fecha
    var fecha = new Date(timestamp);

    // Obtener componentes de fecha y hora
    var anno = fecha.getFullYear();
    var mes = ('0' + (fecha.getMonth() + 1)).slice(-2); // Sumar 1 al mes, ya que en JavaScript los meses van de 0 a 11
    var dia = ('0' + fecha.getDate()).slice(-2);
    var horas = ('0' + fecha.getHours()).slice(-2);
    var minutos = ('0' + fecha.getMinutes()).slice(-2);
    var segundos = ('0' + fecha.getSeconds()).slice(-2);

    // Formato deseado
    return anno + '-' + mes + '-' + dia + ' ' + horas + ':' + minutos + ':' + segundos;
}