// api.js
import axios from 'axios';
import Config from "@/config/servicios/campana/config";
export const report_available = (body) => {
    return axios.post(Config.url_service + Config.report_available,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const available_channels = (body) => {
    return axios.post(Config.url_service + Config.available_channels,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};

export const report_mo_available = (body) => {
    return axios.post(Config.url_service + Config.report_mo_report,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};