// api.js
import axios from 'axios';
import Config from "@/config/servicios/campana/config";
export const contacts_planner = (body) => {
    return axios.post(Config.url_service + Config.contacts_planner,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const edit_contacts_planner = (body) => {
    return axios.post(Config.url_service + Config.contacts_edit_groups,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const contacts_delete = (body) => {
    return axios.post(Config.url_service + Config.contact_delete_contact_group,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const contact_detail_contact_group = (body) => {
    return axios.post(Config.url_service + Config.contact_detail_contact_group,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const customer_field = (body) => {
    return axios.post(Config.url_service + Config.customer_field,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const customer_field_available = (body) => {
    return axios.post(Config.url_service + Config.customer_field_available,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const generic_field = (body) => {
    return axios.post(Config.url_service + Config.generic_field,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const segment = (body) => {
    return axios.post(Config.url_service + Config.segment,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const add_segment = (body) => {
    return axios.post(Config.url_service + Config.segment_add,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const add_tag = (body) => {
    return axios.post(Config.url_service + Config.tag_add,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const get_filtros = (body) => {
    return axios.post(Config.url_service + Config.get_filtros,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};
export const tags = (body) => {
    return axios.post(Config.url_service + Config.tags,body,{
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }});
};