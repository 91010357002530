<template>
  <div class="card">
    <div class="card-header border-1 d-flex justify-content-between align-items-center">
      <h6 class="mb-0">Lista de Usuarios</h6>
      <div class="d-flex">
        <button
            class="btn btn-celcom-orange mb-0"
            data-toggle="tooltip"
            data-original-title="Agregar Contacto"
            @click.prevent="agregar"
        >
          <i class="fas fa-plus"></i> Agregar
        </button>
      </div>
    </div>
    <!-- Agrega un campo de entrada para el buscador -->
    <div class="pb-0 card-header mb-0">
      <input v-model="search" type="text" class="form-control mt-2" placeholder="Buscar...">
    </div>
    <div class="card-body table-responsive">
      <table class="table align-items-center mb-0">
        <thead>
        <tr>
          <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
          >
            Datos del Usuario
          </th>
          <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
          >
            Empresa
          </th>
          <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
          >
            Estado
          </th>
          <th class="text-secondary opacity-7"></th>
        </tr>
        </thead>
        <tbody>
        <!-- Utiliza v-for para recorrer el arreglo y generar filas -->
        <tr v-for="(user, index) in filteredData" :key="index">
          <td>
            <div class="d-flex px-2 py-1">
              <div>
                <img
                    src="@/assets/img/icons/user/userIcono.png"
                    class="avatar avatar-sm me-3"
                />
              </div>
              <div class="d-flex flex-column justify-content-center">
                <h6 class="mb-0 text-xs">{{ user.email }}</h6>
              </div>
            </div>
          </td>
          <td>
            <p class="text-xs font-weight-bold mb-0">{{ user.customer.customerName }}</p>
            <p class="text-xs text-secondary mb-0">{{ user.customer.country }}</p>
          </td>
          <td>
              <span class="badge badge-dot me-4">
                <i :class="{ 'bg-info': user.active, 'bg-dark': !user.active }"></i>
                <span class="text-dark text-xs">{{user.active ? "Activo" : "No activo"}}</span>
              </span>
          </td>
          <td class="align-middle text-center text-sm">
            <button
                class="btn font-weight-bold"
                data-toggle="tooltip"
                data-original-title="Edit user"
                @click.prevent="$parent.openModalEditarCustomerUser(user)"
            >
              <i class="fas fa-pencil-alt me-1"></i> <!-- Ícono de lápiz -->
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReportsTable",
  components: {
  },
  props: {
    userData: {
      type: Array,
      required: true,
    },
  },
  computed: {
    // Utiliza una propiedad computada para filtrar los datos según la búsqueda
    filteredData() {
      if (!Array.isArray(this.userData)) {
        return [];
      }
      return this.userData.filter(user => {
        const email = user.email ?? '';
        const customerName = user.customer.customerName ?? '';

        return (
            email.toLowerCase().includes(this.search.toLowerCase()) ||
            customerName.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
  },
  data() {
    return {
      search: "", // Variable para almacenar la entrada del buscador
    };
  },
  methods: {
    agregar(){
      this.$router.push({ name: "Nuevo Customer User" });
      console.log("Agregar");
    },
  }
};
</script>
