<template>
  <div
      class="card multisteps-form__panel border-radius-xl bg-white"
      data-animation="FadeIn"
  >
    <div class="card-header border-1">
      <h5 class="font-weight-bolder mb-0">Gestión de Contactos</h5>
    </div>
    <div class="card-body multisteps-form__content">
      <div class="col-12 col-sm-6 d-flex align-items-center">
        <div class="form-check">
          <input
              id="individual"
              v-model="seleccion"
              type="radio"
              name="individual"
              value="individual"
              class="form-check-input"
          />
          <label for="individual">Individual</label>
        </div>
      </div>
      <div
          class="row mt-3"
          :style="{
              'pointer-events': seleccion === 'individual' ? 'auto' : 'none',
              'color': seleccion === 'individual' ? 'black' : '#ccc',  // Cambia el color del texto cuando está deshabilitado
              'opacity': seleccion === 'individual' ? '1' : '0.275'      // Reduce la opacidad cuando está deshabilitado
          }"
      >
        <div class="col-12 col-sm-1">
          <argon-input
              id="codigo"
              v-model="codigo"
              class="multisteps-form__input code"
              placeholder="Código"
          />
        </div>
        <div class="col-12 col-sm-6 mt-3 mt-sm-0">
          <argon-input
              id="msisdn"
              v-model="msisdn"
              class="multisteps-form__input"
              type="number"
              placeholder="12345678"
          />
        </div>
        <div class="row mb-4">
          <div
              class="col-12 col-sm-6"
          >
            <label>Seleccione Lista</label>
            <select
                :id="'selected3'"
                v-model="selectedList"
                :disabled="indLista"
                class="multisteps-form__select form-control form-select"
            >
              <option value="" disabled selected>Listas</option>
              <option
                  v-for="(group, tagIndex) in $store.state.responserContactGroups"
                  :key="tagIndex"
                  :value="group.contactGroupId"
              >
                {{ group.name }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-6 d-flex align-items-center">
      <div
          class="form-check"
          :style="{ 'pointer-events': !indLista ? 'auto' : 'none' }"
      >
        <input
            id="lista"
            v-model="seleccion"
            type="radio"
            name="lista"
            value="lista"
            class="form-check-input"
        />
        <label for="lista">Importar lista (csv, excel, etc)</label>
      </div>
      </div>
      <div
          v-if="$store.state.editarContacto === false"
          class="row mt-3"
          :style="{
            'pointer-events': seleccion === 'lista' ? 'auto' : 'none' ,
            'color': seleccion === 'lista' ? 'black' : '#ccc',  // Cambia el color del texto cuando está deshabilitado
            'opacity': seleccion === 'lista' ? '1' : '0.275'      // Reduce la opacidad cuando está deshabilitado
            }"
      >
          <div class="col-12 col-sm-6">
            <label>Nombre de la lista</label>
            <argon-input
                v-model="listaName"
                class="multisteps-form__input"
                type="text"
                placeholder="Nombre de la lista"
            />
        </div>
        <div class="row mt-3">
          <div class="col">
            <div class="card card-body">
              <div
                  id="dropzone"
                  class="form-control dropzone"
              >
                <div class="fallback">
                  <input name="file" type="file" ref="fileInput" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <argon-alert
          v-if="createdContactAux.length > 0 && showAlert"
          ref="successAlert"
          type="success"
          dismissible
          class="alert alert-success"
      >¡Se importaron tus contactos!</argon-alert>
    </div>
    <div class="card-footer border-1 d-flex mt-2">
      <argon-button
        type="button"
        color="celcom"
        variant="gradient"
        class="text-white ms-auto mb-0 js-btn-next"
        @click="nextStepValor"
        >Siguiente <i class="fas fa-chevron-right ms-2"></i>
      </argon-button>
    </div>
  </div>
</template>

<script>
import Dropzone from "dropzone";
import * as XLSX from "xlsx";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonAlert from "@/components/ArgonAlert.vue";

export default {
  name: "ListaAdd1",
  components: {
    ArgonAlert,
    ArgonInput,
    ArgonButton,
  },
  props: {
    createdContact: {
      type: Array,
      default: () => {
        return [];
      },
    },
    validForm: {
      type: Object,
      default: () => ({})
    },
    indLista: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update-values', 'contacto-agregado'],
  data() {
    return {
      selectedList: this.$route.params.id ? this.$route.params.id : "",
      selected1: null,
      selected2: null,
      createdContactAux: [],
      listaName: "",
      seleccion:"individual",
      archivoExcel: null,
      msisdn: (this.validForm.PhoneContact || null),
      codigo: this.validForm.CodePhoneContact,
      showAlert: true,
    };
  },
  watch: {
    validForm: {
      handler(newValidForm) {
        this.msisdn = newValidForm.PhoneContact;
        this.codigo = newValidForm.CodePhoneContact;
        this.listaName = newValidForm.listName;
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    await this.$store.getters.obtenerContactGroups();
    if (this.$store.state.editarContacto === false) {
      Dropzone.autoDiscover = false;
      const dropzoneElement = document.getElementById("dropzone");

      const myDropzone = new Dropzone(dropzoneElement, {
        url: "/file-upload",
        acceptedFiles: ".csv,.xls,.xlsx",
        addRemoveLinks: true,
        dictDefaultMessage: "Arrastra tu archivo aquí.",
      });

      myDropzone.on("addedfile", (file) => {
        // Acción al agregar un archivo
        // Puedes acceder al archivo cargado usando el objeto 'file'

        this.cargarArchivo(file, myDropzone);
      });
    }else {
      this.msisdn = this.$store.state.dataEditarContacto.msisdn;
    }
  },
  methods: {
    agregarContacto(createdContactAux) {
      // Emitir el evento para informar al padre sobre la modificación
      this.$emit('contacto-agregado', createdContactAux);
    },

    // Método que se llama cuando se hace clic en el botón "Siguiente"
    async nextStepValor() {
      if (this.archivoExcel !== null){
        await this.$store.dispatch('addCabeceras', this.archivoExcel[0]);
      }
      if (this.seleccion === 'individual') {
        await this.$store.dispatch('cambiarIndividual', true);
        await this.$store.dispatch('cambiarIndividualNumero', this.codigo + '' + this.msisdn);
        await this.$store.dispatch('cambiarContactGroupId', this.selectedList);
      }
      this.$emit('update-values', {
        msisdn: this.msisdn,
        codigo: this.codigo,
        listaName: this.listaName,});
    },
    formatFileName(text) {
      // Elimina la extensión .xlsx si existe
      let result = text.replace(/\.xlsx\b/, "");

      // Reemplaza todos los espacios por guiones bajos
      result = result.replace(/\s+/g, "_");

      return result;
    },
    cargarArchivo(file, myDropzone) {
      const input = document.getElementById("dropzone").querySelector("input[type='file']");
      const selectedFile = file || input.files[0];
      if (selectedFile) {
        this.listaName = this.formatFileName(selectedFile.upload.filename);
        const reader = new FileReader();
        reader.onload = (e) => {
          try {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];

            // Guardar la hoja en una variable de datos
            this.archivoExcel = XLSX.utils.sheet_to_json(sheet, { header: 1 });

            //let dataTotal = [];
            let dataObjeto = [];

            if (data.length > 0) {
              // Iterar sobre las filas de archivoExcel (empezando desde la segunda fila)
              for (let i = 1; i < this.archivoExcel.length; i++) {
                // Crear un objeto para cada fila
                let filaObjeto = {};

                // Iterar sobre las claves en archivoExcel[0]
                this.archivoExcel[0].forEach((key, index) => {
                  // Asignar las claves y valores al objeto
                  filaObjeto[key] = this.archivoExcel[i][index];
                });

                // Agregar el objeto a dataObjeto
                dataObjeto.push(filaObjeto);
              }
            }
            this.createdContactAux = dataObjeto;
            this.agregarContacto(this.createdContactAux);
            //Ocultar alerta
            if (this.createdContactAux.length > 0) {
              setTimeout(() => {
                this.showAlert = false;
              }, 3000);
            }
            myDropzone.removeAllFiles(true);
          } catch (error) {
            console.error('Error al procesar el archivo:', error);
          }
        };

        reader.readAsArrayBuffer(selectedFile);
      }
    },
  },
};
</script>
<style scoped>
.code {
  width: 70px;
}
</style>
