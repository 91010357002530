<template>
  <div class="container-fluid py-5">
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form">
          <!--form panels-->
          <div class="row">
            <div class="col-12 col-lg-10 m-auto">
              <form class="multisteps-form__form">
                <plantillas-table/>
              </form>
            </div>
          </div>
        </div>
      </div>
      <modal-delete :is-visible="modalVisible">
        <p class="text-center"><b>¿Estás seguro de que deseas eliminar?</b></p>
        <div class="mt-lg-4 d-flex justify-content-between">
          <button class="btn bg-gradient-danger mb-0" @click="deletePlantilla">Eliminar</button>
          <button class="btn bg-gradient-dark mb-0" @click="closeModal">Cancelar</button>
        </div>
      </modal-delete>
      <modal class="modal-lg" :is-visible="modalEditar">
        <p><b>Edite la Plantilla</b></p>
        <div class="row">
          <div class="col-12 col-sm-6">
            <label>Nombre</label>
            <argon-input
                id="contactNameEdit"
                v-model="name"
                class="mb-2"
                type="text"
                placeholder="Nombre de la plantilla"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-6">
            <label>Asunto
              <span
                  v-if="contHeader === 0"
                  type="button"
                  class="badge badge-celcom-orange"
                  title="Haz clic para agregar una variable"
                  @click="addVariable">
                    Agregar nuevo <i class="fas fa-plus fa-xl"></i>
              </span>
            </label>
            <argon-input
                id="textoCabeceraEmail"
                v-model="textoCabecera"
                class="multisteps-form__input m-0"
                type="text"
                placeholder="Variable"
            />
            <div class="text-end mb-2">
              <Emoji @emoji-selected="handleEmojiSelected"/>
            </div>
          </div>
          <div v-if="asunto" class="col-12 col-sm-6">
            <label>Elija Cabecera</label>
            <select
                id="selectCab2"
                v-model="selectedCabsubject"
                class="multisteps-form__select form-control form-select"
                @change="putFieldSubject"
            >
              <option value="" disabled selected>Cabecera</option>
              <option
                  v-for="(cab, cabIndex) in $store.state.responseCustomer"
                  :key="cabIndex"
                  :value="cab"
              >{{ cab.name }}</option>
            </select>
          </div>
        </div>
        <QuillEditor
            ref="quillEditor"
            v-model:content="editorContent"
            content-type="html"
            toolbar="full"
        />
        <div class="text-end mb-2">
          <Emoji :value-body="valueBody" @emoji-selected-body="handleEmojiSelectedBody"/>
        </div>
        <div class="col-12 col-sm-5 mt-2">
          <label>Agregue cabecera al mensaje</label>
          <select
              id="selectCab"
              v-model="selectedCab"
              class="multisteps-form__select form-control form-select"
              @change="putFieldMessage"
          >
            <option value="" disabled selected>Cabecera</option>
            <option
                v-for="(cab, cabIndex) in $store.state.responseCustomer"
                :key="cabIndex"
                :value="cab"
            >{{ cab.name }}</option>
          </select>
        </div>
        <div class="modal-footer px-0 mt-4 d-flex justify-content-between">
          <button class="btn bg-gradient-celcom-orange mb-0" @click="editarPlantilla">Editar</button>
          <button class="btn bg-gradient-dark mb-0" @click="closeModal">Cancelar</button>
        </div>
      </modal>
      <modal class="modal-lg" :is-visible="modalAdd">
        <p><b>Datos de la Plantilla</b></p>
        <div class="row">
          <div class="col-12 col-sm-6">
            <label>Nombre</label>
            <argon-input
                id="contactNameEdit"
                v-model="name"
                class="mb-2"
                type="text"
                placeholder="Nombre de la plantilla"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-6">
            <label>Asunto
              <span
                  v-if="contHeader === 0"
                  type="button"
                  class="badge badge-celcom-orange"
                  title="Haz clic para agregar una variable"
                  @click="addVariable">
                    Agregar nuevo <i class="fas fa-plus fa-xl"></i>
              </span>
            </label>
            <argon-input
                id="textoCabeceraEmail"
                v-model="textoCabecera"
                class="multisteps-form__input m-0"
                type="text"
                placeholder="Asunto del email"
            />
            <div class="text-end mb-2">
              <Emoji @emoji-selected="handleEmojiSelected"/>
            </div>
          </div>
          <div v-if="asunto" class="col-12 col-sm-6">
            <label>Elija Cabecera</label>
            <select
                id="selectCab2"
                v-model="selectedCabsubject"
                class="multisteps-form__select form-control form-select"
                @change="putFieldSubject"
            >
              <option value="" disabled selected>Cabecera</option>
              <option
                  v-for="(cab, cabIndex) in $store.state.responseCustomer"
                  :key="cabIndex"
                  :value="cab"
              >{{ cab.name }}</option>
            </select>
          </div>
        </div>
        <QuillEditor
            ref="quillEditor"
            v-model:content="editorContent"
            content-type="html"
            toolbar="full"
        />
        <div class="text-end mb-2">
          <Emoji :value-body="valueBody" @emoji-selected-body="handleEmojiSelectedBody"/>
        </div>
        <div class="col-12 col-sm-5 mt-2">
          <label>Variables</label>
          <select
              id="selectCab"
              v-model="selectedCab"
              class="multisteps-form__select form-control form-select"
              @change="putFieldMessage"
          >
            <option value="" disabled selected hidden>Seleccione Variable</option>
            <option
                v-for="(cab, cabIndex) in $store.state.responseCustomer"
                :key="cabIndex"
                :value="cab"
            >{{ cab.name }}</option>
          </select>
        </div>
        <div class="modal-footer px-0 mt-4 d-flex justify-content-between js-btn-next">
          <button class="btn bg-gradient-celcom-orange mt-2 mb-0" @click="addPlantilla">Agregar</button>
          <button class="btn bg-gradient-dark mt-2 mb-0" @click="closeModal">Cancelar</button>
        </div>
      </modal>
    </div>
  </div>
</template>

<script>

import {encryptAES, horaAhora, getUser, getUserSub} from "@/config/servicios/campana/util";
import {editPlantilla, deletePlantilla, addPlantilla} from "@/config/servicios/campana/envios/servicesEnvios";
import PlantillasTable from "@/views/envios/components/PlantillasEmailTable.vue";
import Modal from "@/components/Modal/Modal.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import {listaContactosCamp} from "@/config/servicios/campana/contactos/servicesContactosCamp";
import ModalDelete from "@/components/Modal/ModalDelete.vue";
import swal from "sweetalert2";
import { QuillEditor } from '@vueup/vue-quill'
import Emoji from "@/views/envios/whatsapp/Add/components/Emoji.vue";
export default {
  name: "EnvioPlantillaEmail",
  components: {
    ModalDelete,
    ArgonInput, Modal,
    PlantillasTable,
    QuillEditor,
    Emoji,
  },
  data() {
    return {
      contHeader: 0,
      textoCabecera: '',
      asunto: false,
      selectedCabsubject: '',
      editorContent: '', // Variable para almacenar el contenido del editor Quill
      valueBody: true,

      selectedCab: '',
      idPlantilla: null,
      modalVisible: false,
      active: false,
      activeUrl: false,
      selectedListCont: "",
      //response: {},
      responseContact: [],
      modalEditar: false,
      modalAdd: false,
      name: null,
      select: null,
      draftSingle: {
        draftId: null,
        name:"",
        subject: "",
        channelId: 3,
        message: "",
        whatsappSenderId: "",
        created: null,
        master: null,
      },
    };
  },
  watch: {
    message(newValue) {
      console.log('Valor de message:', newValue);
    },
    textoCabecera(newValue) {
      if (!this.textoCabecera.includes('{' + this.selectedCabsubject.name + '}')) {
        this.contHeader = 0;
        this.selectedCabsubject = '';
      }
      this.senderHeader = newValue;
    },
  },
  created() {
    this.$store.commit('RESET_Data_Paginada');
  },
  mounted() {
    this.cargarDatos();
  },
  methods: {
    addVariable() {
      if (this.contHeader === 0) {
        this.contHeader += 1;
        this.asunto = true;
      }
    },
    handleEmojiSelected(emoji) {
      this.textoCabecera = this.textoCabecera + emoji;
    },
    handleEmojiSelectedBody(emoji) {
      // Obtener el contenido HTML actual del editor
      var contenidoActual = this.$refs.quillEditor.getHTML();

      // Concatenar el nuevo contenido HTML al contenido actual
      var contenidoFinal = contenidoActual.replace('</p>', '') + emoji + '</p>';

      // Establecer el contenido HTML completo del editor
      this.$refs.quillEditor.setHTML(contenidoFinal);
    },
    putFieldSubject() {
      this.textoCabecera = this.textoCabecera + '{' + this.selectedCabsubject.name + '}';
      this.asunto = false;
    },
    putFieldMessage() {
      // Obtener el contenido HTML actual del editor
      var contenidoActual = this.$refs.quillEditor.getHTML();

      // Obtener el contenido HTML que deseas agregar
      var nuevoContenidoHTML = "{" + this.selectedCab.name + "}";

      // Concatenar el nuevo contenido HTML al contenido actual
      var contenidoFinal = contenidoActual.replace('</p>', '') + nuevoContenidoHTML + '</p>';

      // Establecer el contenido HTML completo del editor
      this.$refs.quillEditor.setHTML(contenidoFinal);

      this.selectedCab = '';
    },
    findCustomerByContactGroupId(targetContactGroupId) {
      this.foundCustomer = this.responseContact.find(
          (customer) => customer.contactGroupId === targetContactGroupId
      );

      if (this.foundCustomer) {
        return this.foundCustomer;
        // Realiza otras acciones con el Customer encontrado si es necesario.
      } else {
        console.log("No se encontró Customer con contactGroupId:", targetContactGroupId);
      }
    },
    async deletePlantilla() {
      if (this.idPlantilla) {
        try {
          let id = {
            draftId: this.idPlantilla.draftId,
          };

          let user = (getUserSub()!=null) ? getUserSub() :getUser();
          let js = {
            time_expired: horaAhora(),
            customer: encodeURIComponent(JSON.stringify(user.customer)),
            draft: encodeURIComponent(JSON.stringify(id))
          };

          var body = new URLSearchParams();
          body.append("TOKEN", encryptAES(js));

          const response = await deletePlantilla(body);

          if (response.data.response === "200") {
            console.log("Plantilla Eliminada ", response.data.data);
            this.closeModal();
            this.cargarDatos();
          } else {
            console.log("No se pudo eliminar plantilla");

          }
          console.log("Plantilla eliminado" );
        } catch (error) {
          console.error("Error al eliminar plantilla", error);
        } finally {
          this.modalVisible = false;
          await this.cargarDatos();
        }
      }
    },
    openModal(plantilla) {
      this.idPlantilla = plantilla;
      this.modalVisible = true;
    },
    closeModal() {
      this.$refs.quillEditor.setHTML('');
      this.modalVisible = false;
      this.modalEditar = false;
      this.modalAdd = false;
      this.name = null;
      this.textoCabecera = '';
      this.editorContent = '';
      this.select = null;
      this.draftSingle = {
        draftId: null,
        name:"",
        subject: "",
        channelId: 3,
        message: "",
        whatsappSenderId: "",
        created: null,
        master: null,
      };
    },
    openModalEditar(plantilla) {
      const contactData = JSON.parse(JSON.stringify(plantilla));
      console.log(contactData);
      this.name = plantilla.name;
      this.textoCabecera = plantilla.subject ? plantilla.subject : '';
      this.editorContent = plantilla.message;
      this.draftSingle.draftId = plantilla.draftId;
      this.draftSingle.created = plantilla.created;

      this.modalEditar = true;
    },
    async editarPlantilla() {
      try {
        const tiempoActual = new Date().getTime();
        this.draftSingle.name = this.name;
        this.draftSingle.subject = this.textoCabecera;
        this.draftSingle.message = this.editorContent;
        this.draftSingle.updated = tiempoActual;

        if(this.draftSingle.name==="" || this.draftSingle.message===""){
            console.log("Error debe completar los datos requeridos");
        }
        let user = (getUserSub()!=null) ? getUserSub() :getUser();
        console.log(JSON.stringify(this.draftSingle));
        let js = {
          time_expired: horaAhora(),
          customer: encodeURIComponent(JSON.stringify(user.customer)),
          draft: encodeURIComponent(JSON.stringify(this.draftSingle))
        };

        var body = new URLSearchParams();
        body.append("TOKEN", encryptAES(js));


        const response = await editPlantilla(body);
        // Almacena la respuesta en la variable de datos
        if (response.data.response === "200") {
          this.closeModal();
          this.$store.commit('getResponsePlantillasSms', response.data.data);
          swal.fire({
            text: '¡Plantilla editada exitosamente!',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-celcom-blue',
            },
            icon: 'success',
          })
          this.closeModal();
          this.cargarDatos();
        } else {
          swal.fire({
            text: '¡Error al Editar la Plantilla!',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-danger',
            },
            icon: 'error',
          })
        }
      } catch (error) {
        swal.fire({
          text: '¡Error al Editar la Plantilla!',
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-danger',
          },
          icon: 'error',
        })
      }
    },
    openModalAdd() {
      this.name = "";
      this.selectedListCont = "";
      this.draftSingle.message = "";

      this.modalAdd = true;
    },
    async addPlantilla() {
      try {
        const tiempoActual = new Date().getTime();
        this.draftSingle.name = this.name;
        this.draftSingle.subject = this.textoCabecera;
        this.draftSingle.message = this.editorContent;
        this.draftSingle.created = tiempoActual;

        if(this.draftSingle.name==="" || this.draftSingle.message===""){
          console.log("Error debe completar los datos requeridos");
        }
        let user = (getUserSub()!=null) ? getUserSub() :getUser();
        let js = {
          time_expired: horaAhora(),
          customer: encodeURIComponent(JSON.stringify(user.customer)),
          draft: encodeURIComponent(JSON.stringify(this.draftSingle)),
          short_url: this.short_url ? encodeURIComponent(JSON.stringify(this.short_url)) : encodeURIComponent(JSON.stringify({})),
        };

        var body = new URLSearchParams();
        body.append("TOKEN", encryptAES(js));


        const response = await addPlantilla(body);
        // Almacena la respuesta en la variable de datos
        if (response.data.response === "200") {
          console.log("Plantilla Add");
          this.closeModal();
          this.response = response.data.data;
          swal.fire({
            text: '¡Plantilla creada exitosamente!',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-celcom-blue',
            },
            icon: 'success',
          })
          this.closeModal();
          this.cargarDatos();
        } else {
          swal.fire({
            text: '¡No se pudo crear la Plantilla!',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-danger',
            },
            icon: 'error',
          })
        }
      } catch (error) {
        swal.fire({
          text: '¡No se pudo crear la Plantilla!',
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-danger',
          },
          icon: 'error',
        })
      }
    },
    async obtenerLista(){
      await this.$store.getters.plantillasEmailGet();
    },
    async obtenerContactosCamp(){
      try {
        let user = (getUserSub()!=null) ? getUserSub() :getUser();

        let js = {
          "time_expired":horaAhora(),
          "customer_user":encodeURIComponent(JSON.stringify(user)),
          "customer":encodeURIComponent(JSON.stringify(user.customer))
        }

        var body = new URLSearchParams();
        body.append("TOKEN", encryptAES(js));


        const response = await listaContactosCamp(body);
        // Almacena la respuesta en la variable de datos
        if (response.data.response === "200") {

          this.responseContact = response.data.data;
        } else {
          console.log("No se encontró lista");

        }
      } catch (error) {
        console.error("Error al obtener datos", error);
      }
    },
    cargarDatos(){
      this.obtenerLista();
      this.obtenerContactosCamp();
      this.$store.getters.arrayCabeceras();
    }
  },
};
</script>
<style scoped>
/* Agrega estilos según tus necesidades */
.modal {
  display: none;
}

.modal.is-active {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  background: none;
  border: none;
}
.buttons {
  margin: 8px;
}
.espacio{
  margin-bottom: 0;;
}
.espacio1{
  margin-top: 0;
  margin-left: 1px;
  margin-right: 1px;
}
label {
  margin-left: 0;
}
</style>