<template>
  <div class="container-fluid py-4">
    <div class="row mt-4">
      <div class="col-12 col-lg-10 mx-auto mb-4">
        <sender-email-campanna-table :senderEmailData="responseSenderEmail"/>
      </div>
    </div>
      <modal :is-visible="modalAddSender">
        <form>
          <h6 class="heading-small text-muted mb-4">Información de Remitente</h6>
          <div class="row">
            <div class="col-lg-6">
              <label class="form-label">Nombre de Remitente</label>
              <argon-input
                  v-model="fromName"
                  class="multisteps-form__input"
                  type="text"
                  placeholder="Nombre Remitente"
              />
            </div>
            <div class="col-lg-6">
              <label class="form-label">Correo de Remitente</label>
              <argon-input
                  v-model="emailFrom"
                  class="multisteps-form__input"
                  type="email"
                  placeholder="Correo Remitente"
              />
            </div>
          </div>
          <div class="modal-footer px-0 mt-lg-4 d-flex justify-content-between">
            <button class="btn bg-gradient-celcom-orange mt-2 mb-0" @click.prevent="addSenderEmail">Agregar</button>
            <button class="btn bg-gradient-dark mt-2 mb-0" @click.prevent="closeModal">Cancelar</button>
          </div>
        </form>

      </modal>
      <modal :is-visible="modalEditSender">
        <form>
          <h6 class="heading-small text-muted mb-4">Datos del Remitente</h6>
          <div>
            <div class="row">
              <div class="col-lg-6">
                <label class="form-label">Nombre Remitente</label>
                <argon-input
                    placeholder="Nombre Remitente"
                    v-model="dataSender.fromName"
                    class="multisteps-form__input"
                    type="text"
                />
              </div>
              <div class="col-lg-6">
                <label class="form-label">Correo Remitente</label>
                <argon-input
                    placeholder="Correo Remitente"
                    v-model="dataSender.emailFrom"
                    class="multisteps-form__input"
                    type="email"
                />
              </div>
            </div>
            <div class="modal-footer px-0 mt-lg-4 d-flex justify-content-between">
              <button class="btn bg-gradient-celcom-orange mt-2 mb-0" @click.prevent="editSenderEmail">Editar</button>
              <button class="btn bg-gradient-dark mt-2 mb-0" @click.prevent="closeModal">Cancelar</button>
            </div>
          </div>
        </form>
      </modal>
      <modal-delete :is-visible="modalEliminarSender">
        <p class="text-center"><b>¿Estás seguro de que deseas eliminar?</b></p>
        <div class="mt-lg-4 d-flex justify-content-between">
          <button class="btn bg-gradient-danger mt-2 mb-0" @click="borrarSender">Eliminar</button>
          <button class="btn bg-gradient-dark mt-2 mb-0" @click="closeModal">Cancelar</button>
        </div>
      </modal-delete>
  </div>
</template>

<script>
import SenderEmailCampannaTable from "./components/SenderEmailCampannaTable.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import Modal from "@/components/Modal/Modal.vue";
import {encryptAES, getUser, getUserSub, horaAhora} from "@/config/servicios/campana/util";
import swal from "sweetalert2";
import ModalDelete from "@/components/Modal/ModalDelete.vue";
import {sender_add_email, sender_delete_email, sender_edit_email} from "@/config/servicios/campana/sender/servicesSenderEmail";

export default {
  name: "SenderEmailCampanna",
  components: {
    ModalDelete,
    Modal, ArgonInput,
    SenderEmailCampannaTable,
  },
  data() {
    return {
      responseSenderEmail: [],
      dataSender: {},
      modalAddSender: false,
      modalEditSender: false,
      modalEliminarSender: false,
      fromName: '',
      emailFrom: '',
    };
  },
  mounted() {
    this.obtenerSenderEmails();
  },
  methods: {
    async obtenerSenderEmails(){
      this.responseSenderEmail = await this.$store.getters.obtenerSenderEmail();
    },
    closeModal() {
      this.modalAddSender = false;
      this.modalEditSender = false;
      this.modalEliminarSender = false;
      this.fromName = '';
      this.emailFrom = '';
    },

    //Inicio de Eliminar un SenderEmail
    openModalEliminarSender(row) {
      this.dataSender = JSON.parse(JSON.stringify(row));
      this.modalEliminarSender = true;
    },
    async borrarSender() {
      let self = this;
      let user = getUser();

      let js = {
        "time_expired":horaAhora(),
        "sender":encodeURIComponent(JSON.stringify(self.dataSender)),
        "customer":encodeURIComponent(JSON.stringify(user.customer)),
      }

      var body = new URLSearchParams(); body.append('TOKEN', (encryptAES(js)));

      await sender_delete_email(body)
          .then(function (response) {
            if (response.data.response === "200") {
              self.responseSenderEmail = response.data.data;
              self.modalEliminarSender = false;
            } else {
              console.log("No se pudo borrar remitente");
              swal.fire({
                title: 'No se pudo borrar el remitente',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-celcom-orange',
                },
                type: 'error',
                icon: 'error',
              })
            }

          })
          .catch(function (error) {
            console.log("No se pudo borrar el remitente",error);
            swal.fire({
              title: 'No se pudo borrar el remitente',
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-celcom-orange',
              },
              type: 'error',
              icon: 'error',
            })
          });
    },
    //Fin de Eliminar un SenderEmail

    //Inicio de agregar un SenderEmail
    openModalAddSender() {
      this.modalAddSender = true;
    },
    async addSenderEmail(){
      let self = this;
      let user = (getUserSub()!=null) ? getUserSub() :getUser();

      if(this.fromName==="" || this.emailFrom===""){
        await swal.fire({
          title: "Error Debe completar todos los campos",
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-celcom-orange',
          },
          icon: 'error',
        })
        return false;
      }

      let sender =  {
        "emailSenderId": null,
        "emailFrom": self.emailFrom,
        "fromName": self.fromName
      };

      let js = {
        "time_expired":horaAhora(),
        "sender":encodeURIComponent(JSON.stringify(sender)),
        "customer":encodeURIComponent(JSON.stringify(user.customer)),
      }
      var body = new URLSearchParams(); body.append('TOKEN', (encryptAES(js)));
      await sender_add_email(body)
          .then(function (response) {
            if (response.data.response === "200") {

              self.responseSenderEmail = response.data.data;
              self.modalAddSender = false;
              swal.fire({
                title: '¡Remitente agregado con éxito!',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-celcom-blue',
                },
                type: 'success',
                icon: 'success',
              })

            } else {
              console.log("No se pudo agregar el remitente");
              swal.fire({
                title: 'No se pudo agregar el remitente',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-celcom-orange',
                },
                type: 'error',
                icon: 'error',
              })
            }
          })
          .catch(function (error) {
            console.log("No se pudo agregar el remitente",error);
            swal.fire({
              title: 'No se pudo agregar el remitente',
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-celcom-orange',
              },
              type: 'error',
              icon: 'error',
            })
          });
    },
    //Fin de agregar un SenderEmail

    //Inicio de editar un SenderEmail
    openModalEditSender(row) {
      this.dataSender = JSON.parse(JSON.stringify(row));
      this.modalEditSender = true;
    },
    async editSenderEmail(){
      let self = this;
      let user = (getUserSub()!=null) ? getUserSub() :getUser();

      let js = {
        "time_expired":horaAhora(),
        "sender":encodeURIComponent(JSON.stringify(this.dataSender)),
        "customer":encodeURIComponent(JSON.stringify(user.customer)),
      }
      var body = new URLSearchParams(); body.append('TOKEN', (encryptAES(js)));
      await sender_edit_email(body)
          .then(function (response) {
            if (response.data.response === "200") {

              self.responseSenderEmail = response.data.data;
              self.modalEditSender = false;

              swal.fire({
                title: '¡Remitente editado con éxito!',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-celcom-blue',
                },
                type: 'success',
                icon: 'success',
              })

            } else {
              console.log("No se pudo agregar el remitente");
              swal.fire({
                title: 'No se pudo editar el remitente',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-celcom-orange',
                },
                type: 'error',
                icon: 'error',
              })
            }
          })
          .catch(function (error) {
            console.log("No se pudo agregar el remitente",error);
            swal.fire({
              title: 'No se pudo editar el remitente',
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-celcom-orange',
              },
              type: 'error',
              icon: 'error',
            })
          });
    },
    //Fin de editar un SenderEmail
},
};
</script>
<style scoped>
/* Agrega estilos según tus necesidades */
.modal {
  display: none;
}

.modal.is-active {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  background: none;
  border: none;
}
.buttons {
  margin: 8px;
}
</style>