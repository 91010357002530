<template>
  <div
      class="card multisteps-form__panel border-radius-xl bg-white"
      data-animation="FadeIn"
  >
    <div class="card-header border-1">
      <h5 class="font-weight-bolder m-0">Mensaje</h5>
    </div>
    <div class="card-body multisteps-form__content">
      <div v-if="$store.state.seleccion === 'SMS'" class="row">
        <div class="row">
          <label class="form-label mt-2">Tipo de Mensaje</label>
        </div>
        <div class="row ms-1">
          <div class="col-12 col-sm-6">
            <div class="row">
              <div class="form-check col-md-4">
                <input
                    id="redactar"
                    v-model="switchValueMen"
                    type="radio"
                    name="redactar"
                    value="redactar"
                    class="form-check-input"
                    @change="limpiar"
                />
                <label>Redactar</label>
              </div>
              <div class="form-check col-md-4">
                <input
                    id="plantilla"
                    v-model="switchValueMen"
                    type="radio"
                    name="plantilla"
                    value="plantilla"
                    class="form-check-input"
                    @change="limpiar"
                />
                <label>Plantillas</label>
              </div>
            </div>
<!--        <div class="col-12 col-sm-6 d-flex align-items-center">
          <argon-switch
              id="tipo-mensaje"
              v-model="switchValueMen"
              name="tipo-mensaje"
              class="form-check form-switch mb-0 ms-2"
              @change="onSwitchChange"
          />
          <label class="mb-0">{{switchValueMen ? "Plantillas" : "Redactar"}}</label>
        </div>-->
          </div>
        </div>
      </div>
      <div class="row mt-1">
        <div v-if="$store.state.seleccion === 'SMS' && switchValueMen === 'redactar'" class="col-12 col-md-6">
          <label>Mensaje</label>
          <textarea
              id="redactarEdit"
              v-model="message"
              class="form-control espacio"
              rows="3"
              maxlength="160"
              placeholder="Mensaje"
          />
          <p class="text-xs text-end mt-1">Caracteres : {{message.length}}</p>
          <div class="form-check">
            <input
                v-model="activeUrl"
                class="form-check-input"
                type="checkbox"
                @change="putFieldUrl"
            />
            <label class="form-check-label" for="estadoCheckbox">
              Usar URL Corta
            </label>
          </div>
              <div v-if="activeUrl" class="row">
                <div class="form-check col-md-4">
                  <input
                      id="new"
                      v-model="switchValueURL"
                      type="radio"
                      name="new"
                      value="new"
                      class="form-check-input"
                      @change="urlExistente"
                  />
                  <label>URL nueva</label>
                </div>
                <div class="form-check col-md-4">
                  <input
                      id="corta"
                      v-model="switchValueURL"
                      type="radio"
                      name="corta"
                      value="corta"
                      class="form-check-input"
                      @change="urlExistente"
                  />
                  <label>URL existente</label>
                </div>
                <div v-if="!url_existente" class="col-12 col-sm-12">
                  <label>Escriba la URL</label>
                  <argon-input
                      v-model="urlValue"
                      class="multisteps-form__input"
                      type="text"
                      placeholder="https://www.google.com"
                  />
                    <argon-button
                        type="button"
                        color="celcom"
                        variant="gradient"
                        class="text-white ms-auto mb-0 js-btn-next"
                        @click="getShort_url"
                    ><i class="fa fa-scissors ms-2"></i>  Acortar
                    </argon-button>
                  <label v-if="short_url" class="form-check-label" for="estadoCheckbox"> URL corta: {{short_url.shortUrl}}</label>
                </div>
                <div v-if="url_existente" class="col-12 col-sm-12">
                <select
                    id="selectCab"
                    v-model="selectedUrl"
                    class="multisteps-form__select form-control form-select"
                    @change="getUrlExistente"
                >
                  <option value="" disabled selected>URL</option>
                  <option v-for="(url, urlIndex) in $store.state.list_available_short_url"
                          :key="urlIndex"
                          :value="url"
                  >{{ url.longUrl }}</option>
                </select>
                  <label v-if="short_url" class="form-check-label" for="estadoCheckbox"> URL corta: {{short_url.shortUrl}}</label>
              </div>
            </div>
          <div class="col-12 mt-3 form-check">
            <input
                v-model="activeIdSeguimiento"
                class="form-check-input"
                type="checkbox"
                id="estadoCheckbox"
                @change="putFieldUrl"
            />
            <label class="form-check-label" for="estadoCheckbox">
              Usar Id de Seguimiento
            </label>
          </div>
          <div v-if="activeIdSeguimiento" class="row">
            <label>Id de Seguimiento</label>
            <div class="col-sm-6">
              <select
                  id="selectIdSeguimiento"
                  v-model="selectedIdSeguimiento"
                  class="multisteps-form__select form-control form-select"
                  :disabled="idSeguimientos.length > 1"
                  @change="agregarIdSeguimiento"
              >
                <option value="" disabled selected>Ids</option>
                <option v-for="(cab, cabIndex) in $store.state.responseCustomer"
                        :key="cabIndex"
                        :value="cab"
                >{{ traducirHeader(cab.name) }}</option>
              </select>
            </div>
            <div class="col-sm-6">
              <span
                  v-for="id in idSeguimientos"
                  :key="id.name" class="badge badge-celcom-orange me-1 clickable-badge"
                  @click="quitarIdSeguimiento(id)"
              > {{ traducirHeader(id.name) }} </span>
            </div>
          </div>
<br>
          <div>
          <label>Elija Variable</label>
          <select
              id="selectCab"
              v-model="selectedCab"
              class="multisteps-form__select form-control form-select"
              @change="putFieldMessage"
          >
            <option value="" disabled selected>Seleccione Variable</option>
            <option v-for="(cab, cabIndex) in $store.state.responseCustomer"
                    :key="cabIndex"
                    :value="cab"
            >{{ traducirHeader(cab.name) }}</option>
          </select>
          </div>
        </div>
          <div v-if="$store.state.seleccion === 'SMS' && switchValueMen === 'plantilla'" class="row mt-3">
            <div class="col-md-6">
              <label>Seleccionar Plantilla</label>
              <div class="">
                <select
                    id="opcionesSelectDraft"
                    v-model="selectedselectDraftValue"
                    class="multisteps-form__select form-control form-select"
                    name="opciones-ListCont"
                    :disabled="selectedselectDraft.length > 0"
                    @change="addToselectedselectDraft"
                >
                  <option value="" disabled selected>Plantillas</option>
                  <option v-for="option in $store.state.responsePlantillasSms" :key="option.draftId" :value="option">{{option.name}}</option>
                </select>
              </div>
              <div class="col-12 mt-3 form-check">
                <input
                    v-model="activeIdSeguimiento"
                    class="form-check-input"
                    type="checkbox"
                    id="estadoCheckbox"
                    @change="putFieldUrl"
                />
                <label class="form-check-label" for="estadoCheckbox">
                  Usar Id de Seguimiento
                </label>
              </div>
              <br>
              <div v-if="activeIdSeguimiento" class="row">
                <label>Id de Seguimiento</label>
                <div class="col-sm-6">
                  <select
                      id="selectIdSeguimiento"
                      v-model="selectedIdSeguimiento"
                      class="multisteps-form__select form-control form-select"
                      :disabled="idSeguimientos.length > 1"
                      @change="agregarIdSeguimiento"
                  >
                    <option value="" disabled selected>Ids</option>
                    <option v-for="(cab, cabIndex) in $store.state.responseCustomer"
                            :key="cabIndex"
                            :value="cab"
                    >{{ traducirHeader(cab.name) }}</option>
                  </select>
                </div>
                <div class="col-sm-6">
              <span
                  v-for="id in idSeguimientos"
                  :key="id.name" class="badge badge-celcom-orange me-1 clickable-badge"
                  @click="quitarIdSeguimiento(id)"
              > {{ traducirHeader(id.name) }} </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 mt-3">
              <card type="frame" v-for="option in selectedselectDraft"
                    :key="option.draftId">
                <h5>Plantilla : {{option.name}}</h5>
                <p class="text-sm mb-0">{{option.message}}</p>
              </card>
            </div>
          </div>

        <div v-if="$store.state.seleccion === 'Whatsapp'" class="row">
        <label>Seleccione Template</label>
        <div class="col-12 col-sm-6">
          <select
              id="opcionesSelectDraft"
              v-model="selectedTemplateValue"
              @change="addToselectedTemplate"
              class="multisteps-form__select form-control form-select"
              name="opciones-ListCont"
          >
            <option value="" disabled selected>Templates</option>
            <option v-for="option in $store.state.LIST_TEMPLATE_WHATSPP_BY_CUSTOMER" :key="option.whatsappSenderId" :value="option">{{option.templateName}}</option>
          </select>
        </div>
        <div class="col-12 col-sm-6 mt-3 mt-sm-0">
          <card type="frame" v-for="option in selectedselectTemplate"
                :key="option.whatsappSenderId">
            <h5>Template : {{option.templateName}}</h5>
            <p class="text-sm mb-0">{{option.body}}</p>
          </card>
        </div>
          <div class="col-12 ms-3 mt-3 form-check">
            <input
                v-model="activeIdSeguimiento"
                class="form-check-input"
                type="checkbox"
                id="estadoCheckbox"
                @change="putFieldUrl"
            />
            <label class="form-check-label" for="estadoCheckbox">
              Usar Id de Seguimiento
            </label>
          </div>
          <div v-if="activeIdSeguimiento" class="row">
            <label>Id de Seguimiento</label>
            <div class="col-sm-6">
              <select
                  id="selectIdSeguimiento"
                  v-model="selectedIdSeguimiento"
                  class="multisteps-form__select form-control form-select"
                  :disabled="idSeguimientos.length > 1"
                  @change="agregarIdSeguimiento"
              >
                <option value="" disabled selected>Ids</option>
                <option v-for="(cab, cabIndex) in $store.state.responseCustomer"
                        :key="cabIndex"
                        :value="cab"
                >{{ traducirHeader(cab.name) }}</option>
              </select>
            </div>
            <div class="col-sm-6">
              <span
                  v-for="id in idSeguimientos"
                  :key="id.name" class="badge badge-celcom-orange me-1 clickable-badge"
                  @click="quitarIdSeguimiento(id)"
              > {{ traducirHeader(id.name) }} </span>
            </div>
          </div>
          <br>
      </div>
      <div v-if="$store.state.seleccion === 'SMS' && switchValueMen === 'redactar'" class="col-12 col-sm-6 d-flex mt-2">
        <vista-previa-sms
            :body="message"
            :header="sender"/>
      </div>
      </div>
    </div>
    <div class="card-footer border-1 mt-4 d-flex">
      <argon-button
          type="button"
          color="light"
          variant="gradient"
          class="js-btn-prev"
          @click="$parent.prevStep"
      ><i class="fas fa-chevron-left me-2"></i> Anterior
      </argon-button>
      <argon-button
          type="button"
          color="celcom"
          variant="gradient"
          class="text-white ms-auto mb-0 js-btn-next"
          @click="nextStepValor"
        >Siguiente <i class="fas fa-chevron-right ms-2"></i>
      </argon-button>
    </div>
  </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
import Choices from "choices.js";
import {validarRut, validarVacio} from "@/config/validaciones";
import card from "@/views/envios/components/Card.vue"
import VistaPreviaSms from "@/views/envios/components/VistaPreviaSms.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import {traducir_header} from "@/config/servicios/campana/util";

export default {
  name: "ProgramadaEditAdd2",
  components: {
    ArgonInput,
    card,
    ArgonButton,
    VistaPreviaSms,
  },
  props: {
    sender: {
      type: Object,
      default: () => {
        return {};
      },
    },
    seleccionTipoEnvio: {
      type: String,
      default: "",
    },
    sendingEditar: {
      type: Object,
      default: () => ({}),
    },
    editando: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['updateValues2'],
  data() {
    return {
      idSeguimientos: [],
      selectedUrl: '',
      url_existente: false,
      short_url: "",
      urlValue: "",
      activeUrl: false,
      activeIdSeguimiento: false,
      activeShort_url: false,
      selectedCab: '',
      selectedIdSeguimiento: '',
      selectedselectDraftValue: null,
      selectedTemplateValue: null,
      selectedPlanCont: "",
      message: '',
      switchValueMen:  "redactar",
      switchValueURL:  "new",
      rut: "",
      selectedselectDraft: [],
      selectedselectTemplate: [],
      selectedTag: "", // Nuevo dato para almacenar la opción seleccionada
      tags: "",       // Nuevo dato para almacenar los tags seleccionados
    };
  },
  computed: {
    validarRut() {
      return validarRut(this.rut);
    },
    validarVacios() {
      return validarVacio(this.tags);
    },
  },
  watch: {
    sendingEditar: {
      handler(newValidForm) {
        if (newValidForm.messageType === 1) {
          this.message = newValidForm.message;
        }else if (newValidForm.messageType === 2) {
          if (newValidForm.channelId === 1 && this.$store.state.responsePlantillasSms.length > 0){
            const draft = this.$store.state.responsePlantillasSms.find((draft) => draft.draftId === newValidForm.draftFlexId);
            this.selectedselectDraft.push(draft);
            this.selectedselectDraftValue = draft;
            this.switchValueMen = "plantilla";
          }else if (newValidForm.channelId === 2 && this.$store.state.LIST_TEMPLATE_WHATSPP_BY_CUSTOMER.length > 0){
            const template = this.$store.state.LIST_TEMPLATE_WHATSPP_BY_CUSTOMER.find((template) => template.whatsappSenderId === newValidForm.draftFlexId);
            this.selectedselectTemplate.push(template);
            this.selectedTemplateValue = template;
            this.switchValueMen = "plantilla";
          }
        }
        if (newValidForm.trackId && newValidForm.trackId.length > 0) {
          this.activeIdSeguimiento = true;
          newValidForm.trackId.forEach((id) => {
            this.idSeguimientos.push(id);
          });
        }
        // Actualiza otras propiedades según sea necesario
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.$store.getters.arrayCabeceras();
    if (document.getElementById("opcionesTags")) {
      var element = document.getElementById("opcionesTags");
      new Choices(element, {
        searchEnabled: false,
        allowHTML: true,
      });
    }
  },
  methods: {
    traducirHeader(name){
      return traducir_header(name);
    },
    quitarIdSeguimiento(id) {
      this.idSeguimientos = this.idSeguimientos.filter((item) => item !== id);
    },
    agregarIdSeguimiento() {
      if (this.selectedIdSeguimiento !== "" && !this.idSeguimientos.includes(this.selectedIdSeguimiento)){
        this.idSeguimientos.push(this.selectedIdSeguimiento);
      }
    },
    limpiar() {
      this.message = "";
      this.selectedselectDraft = [];
      this.selectedselectTemplate = [];
      this.short_url = "";
      this.urlValue = "";
      this.activeUrl = false;
      this.activeShort_url = false;
      this.selectedCab = "";
      this.selectedselectDraftValue = null;
      this.selectedTemplateValue = null;
      this.selectedTag = "";
      this.tags = "";
    },
    urlExistente() {
      this.url_existente = !this.url_existente;
    },
    getUrlExistente() {
        this.short_url = this.selectedUrl;
    },
    async getShort_url() {
      this.short_url = await this.$store.getters.add_short_urls(this.urlValue);
    },
    putFieldUrl() {
      if (this.activeUrl) {
        this.message += "{URL}";
      } else {
        // Quitar "{URL}" del mensaje
        this.message = this.message.replace("{URL}", "");
      }
    },
    putFieldMessage() {
      this.message += "{" + this.selectedCab.name + "}";
      this.selectedCab = '';
    },
    addToselectedselectDraft() {
      // Verificar si el valor ya está en el arreglo antes de agregarlo

      if (this.selectedselectDraft.indexOf(this.selectedselectDraftValue) === -1) {
        this.selectedselectDraft.push(this.selectedselectDraftValue);
      }
      console.log(JSON.stringify(this.selectedselectDraft));
    },
    addToselectedTemplate() {
      // Verificar si el valor ya está en el arreglo antes de agregarlo

      this.selectedselectTemplate = [];
      if (this.selectedselectTemplate.indexOf(this.selectedTemplateValue) === -1) {
        this.selectedselectTemplate.push(this.selectedTemplateValue);
      }
      console.log(JSON.stringify(this.selectedTemplateValue));
    },
    nextStepValor() {
      // Puedes emitir un evento para notificar al componente padre sobre los cambios
      this.$emit('updateValues2', {
        message: this.message,
        selectDraft: this.selectedselectDraft,
        selectTemplate: this.selectedselectTemplate,
        short_url: this.short_url,
        idSeguimiento: this.idSeguimientos,
      });
      this.$parent.nextStep();
    },
    /*onSwitchChange(event) {
      this.switchValueMen = event.target.checked;
    },*/
    validarVacio,
    updateTags() {
      // Agregar el tag seleccionado al string de tags
      if (this.selectedTag && this.selectedTag !== "..." && !this.tags.includes(this.selectedTag)) {
        if (this.tags.length > 0) {
          this.tags += `, ${this.selectedTag}`;
        } else {
          this.tags = this.selectedTag;
        }
      }
    },
  },
};
</script>
<style scoped>
.clickable-badge {
  cursor: pointer; /* Cambia el cursor a una mano para indicar interactividad */
  transition: background-color 0.3s; /* Suaviza la transición de colores */
}

.clickable-badge:hover {
  background-color: #f8d7da; /* Cambia el color de fondo al pasar el mouse */
}
</style>
