<template>
  <div
      class="card multisteps-form__panel border-radius-xl bg-white"
      data-animation="FadeIn"
  >
    <div class="multisteps-form__content">
      <div class="card-header border-1 bg-transparent">
        <h5 class="font-weight-bolder m-0">Confirmación del envío</h5>
      </div>
      <div class="card-body">

        <h6 class="font-weight-bolder">Vista previa del envío</h6>
        <p class="small">Se muestra un ejemplo de 10 números.</p>

        <div class="table-responsive">
          <table class="table mb-0">
            <thead>
            <tr class="thead-light">
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Correo</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Asunto</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Mensaje</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(row, index) in sendingemail.sendingSmsDetailList" :key="index">
              <td class="align-md-middle text-sm">{{ row.destination }}</td>
              <td class="align-md-middle text-wrap text-sm">{{ row.subject }}</td>
              <td class="align-md-middle text-wrap text-sm box-mensaje w-50" v-html="row.message"></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="card-footer border-1">
        <argon-button @click.prevent="nextStepValor" class="mt-0 d-block ml-auto">
          <i class="fas fa-paper-plane"></i> Enviar
        </argon-button>
      </div>
    </div>
  </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";

export default {
  name: "ProgramadaEditEmailAdd4",
  components: {
    ArgonButton
  },
  props: {
    sendingemail: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  methods: {
    async nextStepValor() {
      if (this.$store.state.seleccion === 'Email') {
        await this.$parent.envioServicesEmail();
      }
    },
  },

};
</script>
<style>
  .box-mensaje img {
    max-width: 100%;
  }
  .box-mensaje p {
    font-size: .875rem;
  }
</style>