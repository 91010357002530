export default {
  url_service: "https://services.celcom.cl",
  url_service_mook: "http://192.168.1.17:3112",
  login: "/rest/protected/user/authenticate?TOKEN=",
  //login: "/rest/protected/user/authenticate?TOKEN=",
  upload_file_url: "/rest/public/uploadFileUrl",

  // Modulos Contacto
  contact_available: "/rest/protected/contact/available",
  contact_available_contact: "/rest/protected/contact/available/contact",
  contact_available_contact_preview: "/rest/protected/contact/available/contact_preview",
  contact_edit_contact: "/rest/protected/contact/edit/contact",
  contact_delete_contact: "/rest/protected/contact/delete/contact",
  contact_edit_contact_group: "/rest/protected/contact/edit/contact_group",
  contact_delete_contact_group: "/rest/protected/contact/delete/contact_group",
  contact_add_contact: "/rest/protected/contact/add/contact",
  contact_add_contacts: "/rest/protected/contact/add/contacts",
  contact_add_contact_group: "/rest/protected/contact/add/contact_group",
  // Modulos Envios
  sending_sms_create_edit: "/rest/protected/contact_planner/sending/create_edit",
  //sending_sms_create_edit: "/rest/protected/sending_sms/create_edit",
  sending_sms_send: "/rest/protected/contact_planner/sending/send",
  //sending_sms_send: "/rest/protected/sending_sms/send",
  sending_sms_available: "/rest/protected/sending_sms/available",
  sending_sms_abort: "/rest/protected/sending_sms/abort",
  sending_sms_get: "/rest/protected/sending_sms/get",

  // Modulos Envios Whatsapp
  sending_whatsapp_create_edit: "/rest/protected/whatsapp/sending/create_edit",
  sending_whatsapp_send: "/rest/protected/whatsapp/sending/send",

  // Modulos Envios Email
  sending_email_create_edit: "/rest/protected/flex_email/sending/create_edit",
  sending_email_send: "/rest/protected/flex_email/sending/send",

  // Modulos sms-link
  landing_available: "/rest/protected/lading/available",
  landing_show: "/rest/protected/lading/show",
  landing_delete: "/rest/protected/lading/delete",

  // Modulos Drafts
  draft_available: "/rest/protected/contact_planner/available/draft",
  //draft_available: "/rest/protected/draft/available",
  draft_edit: "/rest/protected/contact_planner/edit/draft",
  //draft_edit: "/rest/protected/draft/edit",
  draft_add: "/rest/protected/contact_planner/add/draft",
  //draft_add: "/rest/protected/draft/add",
  draft_delete: "/rest/protected/contact_planner/delete/draft",
  //draft_delete: "/rest/protected/draft/delete",

  // Modulos Reportes
  //report_available: "/rest/protected/report/available",
  report_available: "/rest/protected/report/available_flex",
  report_log: "/rest/protected/report/logs",
  report_excel_report: "/protected/report/excel_report?TOKEN=",
  report_excel_report_mo: "/protected/report/excel_report_mo?TOKEN=",
  report_mo_report: "/rest/protected/report/mo_report",
  // Modulos Blacklist
  blacklist_available: "/rest/protected/blacklist/available",
  blacklist_add_blacklist: "/rest/protected/blacklist/add/blacklist",
  blacklist_add_blacklists: "/rest/protected/blacklist/add/blacklists",
  blacklist_delete_blacklist: "/rest/protected/blacklist/delete/blacklist",
  // Modulos Costumer User
  costumer_user_available: "/rest/protected/customer_user/available",
  costumer_user_edit: "/rest/protected/customer_user/edit",
  costumer_user_add: "/rest/protected/customer_user/add",
  customer_user_detele:"/rest/protected/customer_user/delete",
  customer_user_access:"/rest/protected/customer_user/access",
  customer_user_access_edit:"/rest/protected/customer_user/access/edit",
  //Modulos Payment
  payment_products: "/rest/protected/contact_planner/available/product_payment",
  payment_plataforms: "/rest/protected/payment/platform",
  payment_init_payment: "/rest/protected/payment/init_payment",
  payment_gettransaction: "/rest/protected/payment/getTransaction",
  //Modulos Languages
  language_available:"/rest/protected/language/available",
  //Modulos Country
  country_available:"/rest/protected/country/available",
  //Modulos UDH Credits
  udh_validate_credits:"/rest/protected/sending_sms/udh_validate_credits",
  //Modulos Time Serve
  time_serve:"/rest/protected/statistics/time_serve",

  // Modulos Whatsapp
  //Perfiles
  LIST_WSP_ACCOUNT_BY_CUSTOMER: "/rest/protected/whatsapp/available/customerWhatsapp",
  CREATE_WHSTAPP_ACCOUNT_SERVICES: "/rest/protected/whatsapp/addCustomerWhatsapp",
  EDIT_WHSTAPP_ACCOUNT_SERVICES: "/rest/protected/whatsapp/edit/customerWhatsapp",
  //Templates
  LIST_TEMPLATE_WHATSPP_BY_CUSTOMER: "/rest/protected/whatsapp/available/whatsappSender",
  ADD_TEMPLATEWHATSAPP_BY_CUSTOMER: "/rest/protected/whatsapp/addTemplateMedia",
  DELETE_TEMPLATEWHATSAPP_BY_CUSTOMER: "/rest/protected/whatsapp/deleteWhatsappTemplate",

  //Agregar headers custom de customer
  customer_field: "/rest/protected/contact_planner/add/customer_field",
  //Obtener headers custom de customer
  customer_field_available: "/rest/protected/contact_planner/available/custom_field",
  //Obtener headers genericos
  generic_field: "/rest/protected/contact_planner/available/generic_field",

  //Agregar contacts_planner
  contacts_planner: "/rest/protected/contact_planner/add/contacts_planner",
  //Editar un contacts_planner
  edit_contacts_planner: "/rest/protected/contact_planner/edit/contact",
  //Eliminar contacts
  contacts_delete: "/rest/protected/contact_planner/delete/contact",

  //Obtener segmentos
  segment: "/rest/protected/contact_planner/available/segment",
  //Agregar segmentos
  segment_add: "/rest/protected/contact_planner/add/segment",
  //Obtener tags
  tags: "/rest/protected/contact_planner/available/tag",
  //Agregar tags
  tag_add: "/rest/protected/contact_planner/add/tag",

  //Enviar filtros
  get_filtros: "/rest/protected/contact_planner/available/contacts_planner",

  //Modulo Sender
  sender_available: "/rest/protected/contact_planner/available/senders",
  sender_customer: "/rest/protected/contact_planner/available/sender",
  sender_enable: "/rest/protected/contact_planner/edit/sender_enable",
  sender_disable: "/rest/protected/contact_planner/edit/sender_disable",

  //Modulo Sender Email
  sender_available_email: "/rest/protected/flex_email/available/sender",
  sender_delete_email: "/rest/protected/flex_email/delete/sender",
  sender_add_email: "/rest/protected/flex_email/add/sender",
  sender_edit_email: "/rest/protected/flex_email/edit/sender",

  //Modulo Precios
  sender_available_precios: "/rest/protected/pricing/available/prices",
  sender_edit_precios: "/rest/protected/pricing/edit/price",
  sender_edit_available_sms: "/rest/protected/pricing/available/prices_sms",
  sender_edit_precios_sms: "/rest/protected/pricing/edit/price_sms",

  //Modulo Customer Admin
  custom_field: "/rest/protected/admin/available/customer",
  custom_field_add: "/rest/protected/admin/add/customer",
  custom_field_edit: "/rest/protected/admin/edit/customer",
  operator_group: "/rest/protected/admin/available/operator_group",

  //Modulo para acortar URL
  add_short_url: "/rest/protected/contact_planner/add/url",
  //Modulo para obtener URL acortada
  available_short_url: "/rest/protected/contact_planner/available/url",

  //Modulos Contactos
  contact_groups: "/rest/protected/contact_planner/available/contact_groups",
  contacts_delete_groups: "/rest/protected/contact_planner/delete/contact",
  contacts_edit_groups: "/rest/protected/contact_planner/edit/contact_group",
  contact_detail_contact_group: "/rest/protected/contact_planner/detail/contact_group",

  available_countrys: "/rest/protected/country/available",
  available_channels: "/rest/protected/admin/available/channels",

  //Modulo Configuracion MO (SMS)
  mo_response: "/rest/protected/contact_planner/available/mo_response",
  mo_edit: "/rest/protected/contact_planner/edit/mo_response",
  mo_delete: "/rest/protected/contact_planner/delete/mo_response",
  mo_add: "/rest/protected/contact_planner/add/mo_response",

  //Modulo Configuracion MO (Whatsapp)
  mo_edit_whatsapp: "/rest/protected/contact_planner/edit/mo_response",
  mo_delete_whatsapp: "/rest/protected/contact_planner/delete/mo_response",
  mo_add_whatsapp: "/rest/protected/contact_planner/add/mo_response",
}
