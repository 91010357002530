<template>
  <div
    class="card multisteps-form__panel"
    data-animation="FadeIn"
  >
    <div class="card-header border-1">
      <h5 class="font-weight-bolder mb-0">Crear plantilla</h5>
    </div>
    <div class="card-body multisteps-form__content">
      <div class="row">
        <div class="col-12 col-sm-6">
          <label>Nombre de la plantilla*</label>
          <argon-input
            id="plantillaWhatsappName"
            v-model="plantillaName"
            class="multisteps-form__input"
            type="text"
            placeholder="Nombre de la plantilla"
          />
          <div v-if="!validarPlantilla" :class="{ 'is-invalid-d': !validarPlantilla }">
            <p class="mb-0 text-sm">Solo pueden contener letras minúsculas, números y guiones bajos.</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-4 mt-3 mt-sm-0">
          <label>Categoria</label>
          <select
              id="selectedCategoriaWhatsapp"
              v-model="selectedCategoria"
              class="multisteps-form__select form-control form-select"
              name="opciones-Genero"
          >
            <option selected="selected" disabled value="">Categorías</option>
            <option value="marketing">Marketing</option>
            <!-- Agrega más opciones según sea necesario -->
          </select>
        </div>
        <div v-if="selectedCategoria && subCategoriaOptions[selectedCategoria]" class="col-12 col-sm-4 mt-3 mt-sm-0">
          <label>Sub - Categoria</label>
          <select
              id="selectedSubCatedoria"
              v-model="selectedSubCatedoria"
              class="multisteps-form__select form-control form-select"
              name="opciones-subCategoria"
          >
            <option selected="selected" disabled value="">Sub - Categorías</option>
            <option v-for="(option, index) in subCategoriaOptions[selectedCategoria]" :key="index" :value="option">{{ option }}</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-4 mt-3 mt-md-0">
          <label>Idioma</label>
          <select
              id="selectedIdioma"
              v-model="selectedIdioma"
              class="multisteps-form__select form-control form-select"
              name="opciones-Idioma"
          >
            <option selected="selected" disabled value="">Idiomas</option>
            <option value="marketing">Español</option>
            <!-- Agrega más opciones según sea necesario -->
          </select>
        </div>
        <div class="col-12 col-sm-6 mt-3 mt-md-0">
          <label>Etiquetas de plantilla/template*</label>
          <argon-input
              id="plantillaEtiquetasWhatsapp"
              v-model="plantillaEtiquetas"
              class="multisteps-form__input"
              type="text"
              placeholder="OTP, Bienvenida, etc."
          />
<!--                <div v-if="!soloLetra" :class="{ 'is-invalid-d': !soloLetra }">
            <p class="mb-0 text-sm">Defina para qué caso de uso sirve esta plantilla, por ejemplo, actualización de cuenta, OTP, etc. en 2-3 palabras</p>
          </div>-->
        </div>
      </div>
    </div>
    <div class="card-footer d-flex border-1 mt-4">
      <argon-button
        :disabled="!(validarPlantilla)"
        type="button"
        color="celcom"
        variant="gradient"
        class="text-white ms-auto mb-0 js-btn-next"
        @click="$parent.nextStep"
        >Siguiente <i class="fas fa-chevron-right ms-2"></i></argon-button
      >
    </div>
  </div>
</template>

<script>
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import {validarPlantilla} from "@/config/validaciones";
export default {
  name: "Whatsapp1",
  components: {
    ArgonInput,
    ArgonButton,
  },
  data() {
    return {
      text: '',
      plantillaName: "",
      subCategoriaOptions: {
        marketing: ["Mensaje personalizado"],
        //marketing: ["Mensaje personalizado", "Mensaje de producto", "Carrusel", "Oferta por tiempo limitado (LTO)"],
        utilidad: ["Mensaje personalizado", "Carrusel"],
      },
      selectedCategoria: "",
      selectedSubCatedoria: '',
      selectedIdioma: '',
      plantillaEtiquetas: '',
    };
  },
  computed: {
    validarPlantilla() {
      return validarPlantilla(this.plantillaName);
    },
  },
};
</script>
<style scoped>
@import "@/assets/css/validaciones.css";
</style>