<template>
  <div class="card">
    <div class="card-header border-1 d-flex justify-content-between align-items-center flex-column flex-md-row">
      <h6 class="mb-md-0">Lista Contactos</h6>
      <div class="d-flex">
        <button
            class="btn btn-celcom-orange m-0"
            data-toggle="tooltip"
            data-original-title="Agregar Contacto"
            @click.prevent="agregar"
        >
          <i class="fas fa-plus"></i> Nueva Lista
        </button>
      </div>
    </div>

    <!-- Agrega un campo de entrada para el buscador -->
    <div class="pb-0 card-header mb-0">
      <input v-model="search" type="text" class="form-control mt-2" placeholder="Buscar...">
    </div>
    <div class="card-body table-responsive">
      <table class="table align-items-center mb-0">
        <thead>
        <tr>
          <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
          >
            Nombre
          </th>
          <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
          >
            Fecha de envío
          </th>
          <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
          >
            Contactos
          </th>
          <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
          >
            Válidos
          </th>
          <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
          >
            Inválidos
          </th>
          <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
          >
            Estado
          </th>
          <th class="text-secondary opacity-7"></th>
        </tr>
        </thead>
        <tbody>
        <!-- Utiliza v-for para recorrer el arreglo y generar filas -->
        <tr v-for="(lista, index) in $store.state.datosPaginados" :key="index" style="min-height: 3em;">
            <td class="align-middle text-center text-sm">
              <div class="d-flex flex-column justify-content-center" style="white-space: normal; overflow: hidden; height: 3em;">
                <h6 class="mb-0 text-xs">
                  {{ (lista && lista.name) ? lista.name : 'Sin nombre' }}
                </h6>
              </div>
            </td>
            <td class="align-middle text-center text-sm">
              <div class="d-flex flex-column justify-content-center">
                <h6 class="mb-0 text-xs">{{ formatoFecha(lista.created) }}</h6>
              </div>
            </td>
            <td class="align-middle text-center text-sm">
              <div class="d-flex flex-column justify-content-center">
                <h6 class="mb-0 text-xs">{{  lista.validos + lista.invalidos }}</h6>
              </div>
            </td>
            <td class="align-middle text-center text-sm">
              <div class="d-flex flex-column justify-content-center">
                <h6 class="mb-0 text-xs">{{  lista.validos }}</h6>
              </div>
            </td>
            <td class="align-middle text-center text-sm">
              <div class="d-flex flex-column justify-content-center">
                <h6 class="mb-0 text-xs">{{  lista.invalidos }}</h6>
              </div>
            </td>
            <td class="align-middle text-center text-sm">
              <span class="badge badge-dot me-4">
                  <i :class="{ 'bg-info': lista.active, 'bg-dark': !lista.active }"></i>
                  <span class="text-dark text-xs">{{(lista.active===true) ? "Activo" : "Inactivo"}}</span>
                </span>
            </td>
            <td class="align-middle text-center text-sm">
              <button
                  class="btn btn-warning me-2"
                  data-toggle="tooltip"
                  data-original-title="Detalles Lista"
                  @click.prevent="modificarUsuarios(lista)"
              >
                <i class="fas fa-user-check"></i>
              </button>
              <button
                  class="btn btn-info me-2"
                  data-toggle="tooltip"
                  data-original-title="Editar Lista"
                  @click.prevent="$parent.editarListaModal(lista)"
              >
                <img src="../../../assets/iconos/pen-to-square-solid.svg" alt="Cesto de basura" class="icono-borrar">
              </button>
              <button
                  class="btn btn-danger"
                  data-toggle="tooltip"
                  data-original-title="Eliminar Lista"
                  @click.prevent="$parent.openModal(lista)"
              >
                <i class="fas fa-duotone fa-trash"></i>
              </button>
            </td>
        </tr>
        </tbody>
      </table>
    </div>
    <BasePagination :filtered-data="filteredData" />
  </div>
</template>

<script>
import {formatoFecha} from "@/config/config";
import {getUser, getUserSub} from "@/config/servicios/campana/util";
import BasePagination from "@/components/BasePagination.vue";
import Swal from "sweetalert2";
export default {
  name: "ContactosCampanasTable",
  components: {
    BasePagination
  },
  props: {
    listaData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showingTooltip: false,
      dataUserActual: getUser(),
      getUserSub : getUserSub(),
      modalVisible: false,
      search: "", // Variable para almacenar la entrada del buscador
    };
  },
  computed: {
    // Utiliza una propiedad computada para filtrar los datos según la búsqueda
    filteredData() {
      if (!Array.isArray(this.listaData)) {
        return [];
      }
      return this.listaData.filter(lista => {

        const name = lista.name ?? '';
        const validos = lista.validos;
        const invalidos = lista.invalidos;

        return (
            name.toLowerCase().includes(this.search.toLowerCase()) ||
            (validos !== null && validos !== undefined && validos.toString().includes(this.search.toLowerCase())) ||
            (invalidos !== null && invalidos !== undefined && invalidos.toString().includes(this.search.toLowerCase()))
        );
      });
    },
  },
  watch: {
    search() {
      this.$store.getters.getDataPaginated(this.$store.state.paginaActual, this.filteredData);
    },
    filteredData() {
      this.$store.getters.getDataPaginated(this.$store.state.paginaActual, this.filteredData);
    },
  },
  mounted() {
    // Aquí filtramos los datos justo después de que el componente se monta
    for (let i = 0; i < this.listaData.length; i++) {
      console.log(JSON.stringify(this.listaData[i]))
      if (this.listaData[i].isFlex === true){
        alert("Es flex")
      }
    }
  },
  methods: {
    modificarUsuarios(lista) {
      this.$store.commit('setClienteActual', lista); // Guarda el cliente en Vuex
      this.$router.push({ name: "Detalles Lista" });
    },
    showTooltip() {
      this.showingTooltip = true;
    },
    hideTooltip() {
      this.showingTooltip = false;
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatoFecha(fecha) {
      return formatoFecha(fecha);
    },
    openModal() {
      this.modalVisible = true;
    },
    closeModal() {
      this.modalVisible = false;
    },
    agregar() {
      this.$router.push({ name: "Lista Add" });
      console.log("Agregar");
    },
    editarContacto(cont) {
      this.$store.dispatch("cambiarEditarContacto", true);
      this.$store.dispatch("guardarEditarContacto", cont);
      Swal.fire({
        title: "Esta editando un Contacto",
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-celcom-orange',
        },
        icon: "success",
      }).finally(() => {
        this.$router.push({ name: "Lista Add" });
      });
    },
  },
};
</script>

<style scoped>
.btn-plantilla{
  color: #fff;
  background-color: #e64477;
  border-color: #e64477;
}
/* Agrega estilos según tus necesidades */
.icono-rojo {
  color: red;
}
.modal {
  display: none;
}

.modal.is-active {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  background: none;
  border: none;
}
.buttons {
  margin: 8px;
}
.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  position: absolute;
  bottom: 100%; /* Coloca el tooltip arriba del contenido */
  left: 50%;
  transform: translateX(-50%);
  padding: 5px;
  background-color: #333;
  color: #fff;
  border-radius: 3px;
  font-size: 12px;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none; /* Evita que el tooltip sea clickeable */
}

.tooltip-container:hover .tooltip {
  opacity: 1;
}
</style>