<template>
  <div class="table-responsive">
    <table class="table align-items-center mb-0">
      <thead>
      <tr class="thead-light">
        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Cabeceras Genericas</th>
        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Valor</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(tagG, index) in reversedGenericFields" :key="index" class="thead-light">
        <td class="align-middle text-left text-xs font-weight-bolder text-uppercase">{{ tagG.headerName }}</td>
        <td v-if="tagG.headerName === 'birth_date'" class="align-middle text-left text-xs font-weight-bolder text-uppercase">
          <argon-input
              id="datetime-fin"
              v-model="valorInput[index]"
              type="date"
              :class="['multisteps-form__input', { 'invalid-select': valorInput[index] === '' }]"
              placeholder="Selecciona una fecha"
              @input="actualizarTexto(index)"
          />
        </td>
        <td v-else-if="tagG.headerName === 'gender'" class="align-middle text-left text-xs font-weight-bolder text-uppercase">
          <div class="d-flex flex-column justify-content-center mb-3 mb-md-0">
            <select
                v-model="valorInput[index]"
                :class="['multisteps-form__input', { 'invalid-select': valorInput[index] === '' },'multisteps-form__select form-control form-select']"
                @change="actualizarTexto(index)"
            >
              <option value="" disabled selected>Elegir Género</option> 
              <option value="female">Femenino</option>
              <option value="male">Masculino</option>
            </select>
          </div>
        </td>
        <td v-else-if="tagG.headerName === 'email'" class="align-middle text-left text-xs font-weight-bolder text-uppercase">
          <argon-input
              id="email"
              v-model="valorInput[index]"
              :class="['multisteps-form__input', { 'invalid-select': !emailsValid[index] },'multisteps-form__input mb-2']"
              type="email"
              placeholder="argon@dashboard.com"
              @input="actualizarTexto(index)"
          />
          <div v-if="!emailsValid[index]" :class="{ 'is-invalid-d': !emailsValid[index] }">
            <p class="mb-0 text-sm">Ingresa un correo electrónico válido</p>
          </div>
        </td>
        <td v-else class="align-middle text-left text-xs font-weight-bolder text-uppercase">
          <argon-input
              v-model="valorInput[index]"
              :class="['multisteps-form__input', { 'invalid-select': valorInput[index] === '' }]"
              type="text"
              placeholder="Ingrese el valor"
              @input="actualizarTexto(index)"
          />
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>
<script>

import {defineComponent} from "vue";
import ArgonInput from "@/components/ArgonInput.vue";
import {isEmailValid} from "@/config/validaciones";

;

export default defineComponent({
  name: "AddContactInd",
  components: {ArgonInput},
  data() {
    return {
      valorInput: Array.from({ length: this.$store.state.responseGeneric_field.length }, () => ""),
    }
  },
  computed: {
    reversedGenericFields() {
      // Filtrar y omitir el elemento con headerName igual a "msisdn"
      const filteredArray = this.$store.state.responseGeneric_field.filter(item => {
        return item.headerName !== 'msisdn' && item.headerName !== 'tag' && item.headerName !== 'segment';
      });

      // Crear una copia del array filtrado y luego invertirlo
      return [...filteredArray].reverse();
    },
    emailsValid() {
      return this.valorInput.map(email => isEmailValid(email));
    },
  },
  created() {
    this.llenarDatos();
  },
  mounted() {
    if (this.$store.state.editarContacto) {
      this.llenarDatosEdit();
    }
  },
  methods: {
    isEmailValid(index) {
      const email = this.valorInput[index];
      return isEmailValid(email); // Asegúrate de que isValidEmail sea la función correcta para validar correos
    },
    llenarDatosEdit() {
      this.valorInput.forEach((item, index) => {
        switch (index) {
          case 0:
            this.valorInput[index] = this.$store.state.dataEditarContacto.contactName;
            this.$store.dispatch('actualizarcabecerasIndGenericas', { id: index, valor: this.valorInput[index] });
            break;
          case 1:
            this.valorInput[index] = this.$store.state.dataEditarContacto.contact_last_name;
            this.$store.dispatch('actualizarcabecerasIndGenericas', { id: index, valor: this.valorInput[index] });
            break;
          case 2:
            this.valorInput[index] = this.$store.state.dataEditarContacto.address;
            this.$store.dispatch('actualizarcabecerasIndGenericas', { id: index, valor: this.valorInput[index] });
            break;
          case 3:
            this.valorInput[index] = this.$store.state.dataEditarContacto.email;
            this.$store.dispatch('actualizarcabecerasIndGenericas', { id: index, valor: this.valorInput[index] });
            break;
          case 4:
            this.valorInput[index] = this.$store.state.dataEditarContacto.birthDate;
            this.$store.dispatch('actualizarcabecerasIndGenericas', { id: index, valor: this.valorInput[index] });
            break;
          case 5:
            this.valorInput[index] = this.$store.state.dataEditarContacto.company;
            this.$store.dispatch('actualizarcabecerasIndGenericas', { id: index, valor: this.valorInput[index] });
            break;
          case 6:
            this.valorInput[index] = this.$store.state.dataEditarContacto.gender;
            this.$store.dispatch('actualizarcabecerasIndGenericas', { id: index, valor: this.valorInput[index] });
            break;
          case 7:
            this.valorInput[index] = this.$store.state.dataEditarContacto.tax;
            this.$store.dispatch('actualizarcabecerasIndGenericas', { id: index, valor: this.valorInput[index] });
            break;
          case 8:
            this.valorInput[index] = this.$store.state.dataEditarContacto.country;
            this.$store.dispatch('actualizarcabecerasIndGenericas', { id: index, valor: this.valorInput[index] });
            break;
          default:
            this.valorInput[index] = null;
            break;
        }
      });
    },
    llenarDatos(){
      this.reversedGenericFields.forEach((item, index) => {
        this.$store.dispatch('agregarcabecerasIndGenericas', {
          id: index,
          name: item.headerName,
          valor: '',
        });
      });
    },
    actualizarTexto(index) {
      // Emitir un evento al componente padre (BotonRR) con el nuevo texto
      this.$store.dispatch('actualizarcabecerasIndGenericas', { id: index, valor: this.valorInput[index] });
    },
  },
})
</script>
<style scoped>
.invalid-select {
  border: 1px solid red;
  background-color: #ffe6e6; /* Cambia el color de fondo a rojo claro */
}
</style>