<template>
  <div class="container-fluid py-5">
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form">
          <!--form panels-->
          <div class="row">
            <div class="col-12 col-lg-10 m-auto">
              <form class="multisteps-form__form">
                <plantillas-table/>
              </form>
            </div>
          </div>
        </div>
      </div>
      <modal-delete :is-visible="modalVisible">
        <p class="text-center"><b>¿Estás seguro de que deseas eliminar?</b></p>
        <div class="mt-lg-4 d-flex justify-content-between">
          <button class="btn bg-gradient-danger mb-0" @click="deletePlantilla">Eliminar</button>
          <button class="btn bg-gradient-dark mb-0" @click="closeModal">Cancelar</button>
        </div>
      </modal-delete>
      <modal :is-visible="modalEditar">
        <p><b>Edite la Plantilla</b></p>
        <div class="row">
          <div class="col-12 col-sm-6">
            <label>Nombre</label>
            <argon-input
              v-model="name"
              id="contactNameEdit"
              class="mb-2"
              type="text"
              placeholder="Nombre"
            />
          </div>
<!--          <div class="col-12 col-sm-6">
            <label>Master</label>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                id="estadoCheckboxAddCustomer"
                v-model="active"
              />
              <label class="form-check-label" for="estadoCheckbox">
                Master
              </label>
            </div>
          </div>-->
        </div>

        <div class="row">
          <div class="col-12">
            <label>Redactar mensaje</label>
            <textarea
                id="mensajeEdit"
                v-model="draftSingle.message"
                class="form-control espacio"
                placeholder="Mensaje"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-6">
            <label>Elija Cabecera</label>
            <select
                id="selectCab"
                v-model="selectedCab"
                class="form-control form-select"
                @change="putFieldMessage"
            >
              <option value="" disabled selected>Cabeceras</option>
              <option v-for="(cab, cabIndex) in $store.state.responseCustomer"
                      :key="cabIndex"
                      :value="cab"
              >{{ cab.name }}</option>
            </select>
          </div>
        </div>
        <div class="modal-footer px-0 mt-4 d-flex justify-content-between">
          <button class="btn bg-gradient-celcom-orange mb-0" @click="editarPlantilla">Editar</button>
          <button class="btn bg-gradient-dark mb-0" @click="closeModal">Cancelar</button>
        </div>
      </modal>
      <modal :is-visible="modalAdd">
        <p><b>Datos de la Plantilla</b></p>
        <div class="row">
          <div class="col-12 col-sm-6">
            <label>Nombre</label>
            <argon-input
              v-model="name"
              id="contactNameEdit"
              type="text"
              class="mb-2"
              placeholder="Nombre"
            />
          </div>
<!--          <div class="col-12 col-sm-6">
            <label>Master</label>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                id="estadoCheckboxAddCustomer"
                v-model="active"
              />
              <label class="form-check-label" for="estadoCheckbox">
                Master
              </label>
            </div>
          </div>-->
        </div>
        <div class="row">
          <div class="col-12 col-sm-8">
            <label>Redactar mensaje</label>
            <textarea
                id="mensajeEdit"
                v-model="draftSingle.message"
                class="form-control mb-2"
                placeholder="Mensaje"
            />
            <div class="form-check">
              <input
                  v-model="activeUrl"
                  class="form-check-input"
                  type="checkbox"
                  @change="putFieldUrl"
                  id="cabeceraURLAdd"
              />
              <label class="form-check-label" for="cabeceraURLAdd">
                Cabecera URL
              </label>
            </div>
          </div>
          <div class="col-12 col-sm-4">
            <label>Elija Cabecera</label>
            <select
                id="selectCab"
                v-model="selectedCab"
                class="multisteps-form__select form-control form-select"
                @change="putFieldMessage"
            > 
              <option value="" disabled selected>Cabeceras</option>
              <option v-for="(cab, cabIndex) in $store.state.responseCustomer"
                      :key="cabIndex"
                      :value="cab"
              >{{ cab.name }}</option>
            </select>
          </div>
        </div>
        <div v-if="activeUrl" class="row mt-3">
          <div class="col-12 col-sm-10">
            <label>Escriba la URL
              <span class="badge badge-celcom-orange" @click="urlExistente">Si Existe presione aquí</span>
            </label>
            <argon-input
                v-if="!url_existente"
                v-model="urlValue"
                class="multisteps-form__input"
                type="text"
                placeholder="https://www.google.com"
            />
            <div class="form-check">
              <input
                  v-if="!url_existente"
                  v-model="activeShort_url"
                  class="form-check-input"
                  type="checkbox"
                  @change="getShort_url"
                  id="urlCorta"
              />
              <select
                  v-if="url_existente"
                  id="selectCab"
                  v-model="selectedUrl"
                  class="multisteps-form__select form-control form-select"
                  @change="getUrlExistente"
              >
                <option v-for="(url, urlIndex) in $store.state.list_available_short_url"
                        :key="urlIndex"
                        :value="url"
                >{{ url.longUrl }}</option>
              </select>
              <label class="form-check-label" for="urlCorta">
                URL corta es: {{short_url.shortUrl}}
              </label>
            </div>
          </div>
        </div>
        <div class="modal-footer px-0 mt-4 d-flex justify-content-between js-btn-next">
          <button class="btn bg-gradient-celcom-orange mt-2 mb-0" @click="addPlantilla">Agregar</button>
          <button class="btn bg-gradient-dark mt-2 mb-0" @click="closeModal">Cancelar</button>
        </div>
      </modal>
    </div>
  </div>
</template>

<script>

import {encryptAES, horaAhora, getUser, getUserSub} from "@/config/servicios/campana/util";
import {editPlantilla, deletePlantilla, addPlantilla} from "@/config/servicios/campana/envios/servicesEnvios";
import PlantillasTable from "@/views/envios/components/PlantillasTable.vue";
import Modal from "@/components/Modal/Modal.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import {listaContactosCamp} from "@/config/servicios/campana/contactos/servicesContactosCamp";
import ModalDelete from "@/components/Modal/ModalDelete.vue";
import swal from "sweetalert2";

export default {
  name: "EnvioPlantilla",
  components: {
    ModalDelete,
    ArgonInput, Modal,
    PlantillasTable,
  },
  data() {
    return {
      selectedUrl: '',
      url_existente: false,
      short_url: "",
      activeShort_url: false,
      urlValue: '',
      selectedCab: '',
      idPlantilla: null,
      modalVisible: false,
      active: false,
      activeUrl: false,
      selectedListCont: "",
      //response: {},
      responseContact: [],
      modalEditar: false,
      modalAdd: false,
      name: null,
      select: null,
      draftSingle: {
        draftId: null,
        name:"",
        channelId: 1,
        message: "",
        whatsappSenderId: "",
        created: null,
        master: null,
      },
    };
  },
  watch: {
    message(newValue) {
      console.log('Valor de message:', newValue);
    },
  },
  created() {
    this.$store.commit('RESET_Data_Paginada');
  },
  mounted() {
    this.cargarDatos();
  },
  methods: {
    urlExistente() {
      this.url_existente = !this.url_existente;
    },
    async getShort_url() {
      this.short_url = await this.$store.getters.add_short_urls(this.urlValue);
    },
    getHeaderParts(header) {
      console.log(header)
      if (header) {
        return header.split('|');
      } else {
        return ["PHONE"];
      }
    },
    getUrlExistente() {
      this.short_url = this.selectedUrl;
    },
    putFieldMessage() {
      this.draftSingle.message += "{" + this.selectedCab.name + "}";
      this.selectedCab = '';
    },
    putFieldUrl() {
      if (this.activeUrl) {
        this.draftSingle.message += "{URL}";
      } else {
        // Quitar "{URL}" del mensaje
        this.draftSingle.message = this.draftSingle.message.replace("{URL}", "");
      }
    },
    findCustomerByContactGroupId(targetContactGroupId) {
      this.foundCustomer = this.responseContact.find(
          (customer) => customer.contactGroupId === targetContactGroupId
      );

      if (this.foundCustomer) {
        return this.foundCustomer;
        // Realiza otras acciones con el Customer encontrado si es necesario.
      } else {
        console.log("No se encontró Customer con contactGroupId:", targetContactGroupId);
      }
    },
    async deletePlantilla() {
      if (this.idPlantilla) {
        try {
          let id = {
            draftId: this.idPlantilla.draftId,
          };

          let user = (getUserSub()!=null) ? getUserSub() :getUser();
          let js = {
            time_expired: horaAhora(),
            customer: encodeURIComponent(JSON.stringify(user.customer)),
            draft: encodeURIComponent(JSON.stringify(id))
          };

          var body = new URLSearchParams();
          body.append("TOKEN", encryptAES(js));

          const response = await deletePlantilla(body);

          if (response.data.response === "200") {
            console.log("Plantilla Eliminada ", response.data.data);
            this.closeModal();
            this.cargarDatos();
          } else {
            console.log("No se pudo eliminar plantilla");

          }
          console.log("Plantilla eliminado" );
        } catch (error) {
          console.error("Error al eliminar plantilla", error);
        } finally {
          this.modalVisible = false;
          await this.cargarDatos();
        }
      }
    },
    openModal(plantilla) {
      this.idPlantilla = plantilla;
      this.modalVisible = true;
    },
    closeModal() {
      this.modalVisible = false;
      this.modalEditar = false;
      this.modalAdd = false;
      this.name = null;
      this.select = null;
      this.short_url = "";
      this.activeShort_url = false;
      this.urlValue = '';
      this.activeUrl = false;
    },
    openModalEditar(plantilla) {
      const contactData = JSON.parse(JSON.stringify(plantilla));
      console.log(contactData);
      this.name = plantilla.name;
      this.draftSingle.message = plantilla.message;
      this.active = plantilla.master;
      this.draftSingle.draftId = plantilla.draftId;
      this.draftSingle.created = plantilla.created;

      this.modalEditar = true;
    },
    async editarPlantilla() {
      try {
        const tiempoActual = new Date().getTime();
        this.draftSingle.name = this.name;
        this.draftSingle.master = this.active;
        this.draftSingle.updated = tiempoActual;

        if(this.draftSingle.name==="" || this.draftSingle.message===""){
            console.log("Error debe completar los datos requeridos");
        }
        let user = (getUserSub()!=null) ? getUserSub() :getUser();
        console.log(JSON.stringify(this.draftSingle));
        let js = {
          time_expired: horaAhora(),
          customer: encodeURIComponent(JSON.stringify(user.customer)),
          draft: encodeURIComponent(JSON.stringify(this.draftSingle))
        };

        var body = new URLSearchParams();
        body.append("TOKEN", encryptAES(js));


        const response = await editPlantilla(body);
        // Almacena la respuesta en la variable de datos
        if (response.data.response === "200") {
          this.$store.commit('getResponsePlantillasSms', response.data.data);
          swal.fire({
            text: '¡Plantilla editada exitosamente!',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-celcom-blue',
            },
            icon: 'success',
          })
          this.closeModal();
          this.cargarDatos();
        } else {
          swal.fire({
            text: '¡Error al Editar la Plantilla!',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-danger',
            },
            icon: 'error',
          })
        }
      } catch (error) {
        swal.fire({
          text: '¡Error al Editar la Plantilla!',
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-danger',
          },
          icon: 'error',
        })
      }
    },
    openModalAdd() {
      this.name = "";
      this.selectedListCont = "";
      this.draftSingle.message = "";

      this.modalAdd = true;
    },
    async addPlantilla() {
      try {
        const tiempoActual = new Date().getTime();
        this.draftSingle.name = this.name;
        this.draftSingle.master = this.active;
        this.draftSingle.created = tiempoActual;

        if(this.draftSingle.name==="" || this.draftSingle.message===""){
          console.log("Error debe completar los datos requeridos");
        }
        let user = (getUserSub()!=null) ? getUserSub() :getUser();
        let js = {
          time_expired: horaAhora(),
          customer: encodeURIComponent(JSON.stringify(user.customer)),
          draft: encodeURIComponent(JSON.stringify(this.draftSingle)),
          short_url: this.short_url ? encodeURIComponent(JSON.stringify(this.short_url)) : encodeURIComponent(JSON.stringify({})),
        };

        var body = new URLSearchParams();
        body.append("TOKEN", encryptAES(js));


        const response = await addPlantilla(body);
        // Almacena la respuesta en la variable de datos
        if (response.data.response === "200") {
          console.log("Plantilla Add");
          this.response = response.data.data;
          swal.fire({
            text: '¡Plantilla creada exitosamente!',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-celcom-blue',
            },
            icon: 'success',
          })
          this.closeModal();
          this.cargarDatos();
        } else {
          swal.fire({
            text: '¡No se pudo crear la Plantilla!',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-danger',
            },
            icon: 'error',
          })
        }
      } catch (error) {
        swal.fire({
          text: '¡No se pudo crear la Plantilla!',
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-danger',
          },
          icon: 'error',
        })
      }
    },
    async obtenerLista(){
      await this.$store.getters.plantillasSmsGet();
      await this.$store.getters.available_short_urls();
      /*try {
        let user = (getUserSub()!=null) ? getUserSub() :getUser();
        let js = {
          time_expired: horaAhora(),
          customer: encodeURIComponent(JSON.stringify(user.customer))
        };

        var body = new URLSearchParams();
        body.append("TOKEN", encryptAES(js));


        const response = await obtenerLista(body);
        // Almacena la respuesta en la variable de datos
        if (response.data.response === "200") {
          this.response = response.data.data;
        } else {
          console.log("No se encontró lista");

        }
      } catch (error) {
        console.error("Error al obtener datos", error);
      }*/
    },
    async obtenerContactosCamp(){
      try {
        let user = (getUserSub()!=null) ? getUserSub() :getUser();

        let js = {
          "time_expired":horaAhora(),
          "customer_user":encodeURIComponent(JSON.stringify(user)),
          "customer":encodeURIComponent(JSON.stringify(user.customer))
        }

        var body = new URLSearchParams();
        body.append("TOKEN", encryptAES(js));


        const response = await listaContactosCamp(body);
        // Almacena la respuesta en la variable de datos
        if (response.data.response === "200") {

          this.responseContact = response.data.data;
        } else {
          console.log("No se encontró lista");

        }
      } catch (error) {
        console.error("Error al obtener datos", error);
      }
    },
    cargarDatos(){
      this.obtenerLista();
      this.obtenerContactosCamp();
      this.$store.getters.arrayCabeceras();
    }
  },
};
</script>
<style scoped>
/* Agrega estilos según tus necesidades */
.modal {
  display: none;
}

.modal.is-active {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  background: none;
  border: none;
}
.buttons {
  margin: 8px;
}
.espacio{
  margin-bottom: 0;;
}
.espacio1{
  margin-top: 0;
  margin-left: 1px;
  margin-right: 1px;
}
label {
  margin-left: 0;
}
</style>