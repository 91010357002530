<template>
  <div class="card">
    <div
      class="card-header border-1 d-flex justify-content-between align-items-center"
    >
      <div>
        <h6 class="mb-0">Lista</h6>
        <div class="d-flex align-items-center">
          <h6 v-show="!cambiarNombre" class="mb-0" style="margin-left: 2px">
            {{ contactGroup.name }}
          </h6>
          <a
            v-show="!cambiarNombre"
            class="font-weight-600 text-sm p-md-2 p-1 mb-0 ml-md-2"
            href="#!"
            @click.prevent="$parent.cambiarNomb()"
          >
            <i class="fas fa-pen"></i>
          </a>
          <form v-show="cambiarNombre" role="form">
            <div
              class="row ml-0 ml-md-2 justify-content-md-between flex-column flex-md-row"
            >
              <argon-input
                v-model="editedContactGroupName.name"
                class="form-control-flush form-control-sm text-celcomblue font-weight-600"
                placeholder="Nombre"
                @keyup.enter="
                  $parent.guardarEdicionGroupName(editedContactGroupName)
                "
              ></argon-input>

              <div class="d-flex">
                <button
                  class="btn font-weight-bold btn-celcom-orange mr-2"
                  @click.prevent="
                    $parent.guardarEdicionGroupName(editedContactGroupName)
                  "
                >
                  Guardar
                </button>
                <button
                  class="btn font-weight-bold btn-celcom-orange mr-2"
                  @click.prevent="$parent.cambiarNomb()"
                >
                  Cerrar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="col-md d-flex justify-content-end px-0">
        <button
          class="btn btn-celcom-orange mr-2"
          data-toggle="tooltip"
          data-original-title="Agregar Contacto"
          @click.prevent="$parent.abrirAgregar()"
        >
          Agregar contacto
          <i class="fas fa-user-plus"></i>
        </button>
        <button
          class="btn btn-celcom-orange"
          data-toggle="tooltip"
          data-original-title="Agregar Contacto"
          @click.prevent="agregar"
        >
          Importar lista
          <i class="fas fa-address-book"></i>
        </button>
      </div>
    </div>

    <!-- Agrega un campo de entrada para el buscador -->
    <div class="pb-0 card-header mb-0">
      <input
        v-model="search"
        type="text"
        class="form-control mt-2"
        placeholder="Buscar..."
      />
    </div>
    <div class="table-responsive pb-4">
      <table class="table align-items-center mb-0">
        <thead>
          <tr>
            <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Código de área
            </th>
            <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              Teléfono
            </th>
            <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Estado
            </th>
            <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Acción
            </th>
          </tr>
        </thead>
        <tbody>
          <!-- Utiliza v-for para recorrer el arreglo y generar filas -->
          <tr
            v-for="(lista, index) in filteredData"
            :key="index"
            style="min-height: 3em"
          >
            <td class="align-middle text-center text-sm">
              <div
                class="d-flex flex-column justify-content-center"
                style="white-space: normal; overflow: hidden; height: 3em"
              >
                <h6 class="mb-0 text-xs">
                  {{
                    lista && lista.phoneCode ? lista.phoneCode : "Sin phoneCode"
                  }}
                </h6>
              </div>
            </td>
            <td class="align-middle text-center text-sm">
              <div class="d-flex flex-column justify-content-center">
                <h6 class="mb-0 text-xs">{{ lista.phone }}</h6>
              </div>
            </td>
            <td class="align-middle text-center text-sm">
              <span class="badge badge-dot me-4">
                <i
                  :class="{ 'bg-info': lista.status, 'bg-dark': !lista.status }"
                ></i>
                <span class="text-dark text-xs">{{
                  lista.status === true ? "Activo" : "Inactivo"
                }}</span>
              </span>
            </td>
            <td class="align-middle text-center text-sm">
              <button
                v-show="permisos.modify"
                class="btn btn-info"
                data-toggle="tooltip"
                data-original-title="Edit user"
                @click.prevent="$parent.openModalEditar(lista)"
              >
                <img
                  src="../../../assets/iconos/pen-to-square-solid.svg"
                  alt="Cesto de basura"
                  class="icono-borrar"
                />
              </button>
              <button
                v-show="permisos.destroy"
                class="btn btn-danger"
                data-toggle="tooltip"
                data-original-title="Eliminar user"
                @click.prevent="$parent.openModal(lista)"
              >
                <i class="fas fa-duotone fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { formatoFecha } from "@/config/config";
import { getUser, getUserSub } from "@/config/servicios/campana/util";
import ArgonInput from "@/components/ArgonInput.vue";
export default {
  name: "ContactosListaTable",
  components: {
    ArgonInput,
  },
  props: {
    cambiarNombre: {
      type: Boolean,
      required: true,
    },
    contactGroup: {
      type: Object,
      required: true,
    },
    permisos: {
      type: Object,
      required: true,
    },
    listaData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      editedContactGroupName: this.contactGroup,
      showingTooltip: false,
      dataUserActual: getUser(),
      getUserSub: getUserSub(),
      modalVisible: false,
      search: "", // Variable para almacenar la entrada del buscador
    };
  },
  computed: {
    // Utiliza una propiedad computada para filtrar los datos según la búsqueda
    filteredData() {
      if (!Array.isArray(this.listaData)) {
        return [];
      }
      return this.listaData.filter((lista) => {
        const phoneCode = lista.phoneCode ?? "";
        const phone = lista.phone;

        return (
          (phoneCode !== null &&
            phoneCode !== undefined &&
            phoneCode.toString().includes(this.search.toLowerCase())) ||
          (phone !== null &&
            phone !== undefined &&
            phone.toString().includes(this.search.toLowerCase()))
        );
      });
    },
  },
  methods: {
    onEditContactGroupName(row) {
      let self = this;
      self.contactGroup = row;
      self.$parent.cambiarNomb();
    },
    cambiarTabContact(valor) {
      this.$emit("cambiar-tab-contact", valor);
    },
    showTooltip() {
      this.showingTooltip = true;
    },
    hideTooltip() {
      this.showingTooltip = false;
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatoFecha(fecha) {
      return formatoFecha(fecha);
    },
    openModal() {
      this.modalVisible = true;
    },
    closeModal() {
      this.modalVisible = false;
    },
    agregar() {
      this.$router.push({ name: "Programada Edit Add" });
      console.log("Agregar");
    },
  },
};
</script>

<style scoped>
.btn-plantilla {
  color: #fff;
  background-color: #e64477;
  border-color: #e64477;
  margin: 2px;
}
/* Agrega estilos según tus necesidades */
.icono-rojo {
  color: red;
}
.modal {
  display: none;
}

.modal.is-active {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  background: none;
  border: none;
}
.buttons {
  margin: 8px;
}
.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  position: absolute;
  bottom: 100%; /* Coloca el tooltip arriba del contenido */
  left: 50%;
  transform: translateX(-50%);
  padding: 5px;
  background-color: #333;
  color: #fff;
  border-radius: 3px;
  font-size: 12px;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none; /* Evita que el tooltip sea clickeable */
}

.tooltip-container:hover .tooltip {
  opacity: 1;
}
</style>
