<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row mb-3">
          <div
            :class="
              user.customer.monthlyLimit && !user.customer.postpaid
                ? 'col-sm-8 mb-3 mb-md-0'
                : 'col-12'
            "
          >
            <gradient-line-chart
              v-if="cargar"
              id="chart-line"
              title="Envíos"
              description="Últimos 7 días"
              :chart="{
                labels: barChart.chartData.labels,
                datasets: [
                  {
                    label: barChart.chartData.datasets[0].label,
                    data: barChart.chartData.datasets[0].data,
                  },
                  {
                    label: barChart.chartData.datasets[1].label,
                    data: barChart.chartData.datasets[1].data,
                  },
                  {
                    label: barChart.chartData.datasets[2].label,
                    data: barChart.chartData.datasets[2].data,
                  },
                ],
              }"
            />
          </div>
          <div
            class="col-md-4"
            :style="{
              display:
                user.customer.monthlyLimit && !user.customer.postpaid
                  ? 'block'
                  : 'none',
            }"
          >
            <categories-list
              v-if="productos.length > 0"
              :key="productos"
              :title="'AUMENTE SU SALDO'"
              @accion="irCompra"
              :categories="
                productos.map((product) => ({
                  icon: {
                    component: 'ni ni-cart',
                    background: 'celcom',
                  },
                  label: numberWithCommas(product.productName),
                  description: numberWithCommas(product.amount),
                  payProductId: product.payProductId,
                  // Puedes agregar más propiedades según sea necesario
                }))
              "
              class="h-100"
            />
          </div>
        </div>
        <div class="row mt-3 mt-md-0">
          <div class="col-md-6 col-12">
            <mini-statistics-card
              title="Saldo Disponible"
              :value="
                saldoDisponible(
                  getUserSub != null
                    ? getUserSub.customer.flexCredits
                    : user.customer.flexCredits
                )
              "
              :icon="{
                component: 'fas fa-mail-bulk',
                background: 'bg-gradient-primary',
                shape: 'rounded-circle',
              }"
              :currency-code="user.customer.country.currencyCode"
            />
          </div>
          <div class="col-md-6 col-12">
            <a href="/contactos/lista-contactos">
              <mini-statistics-card
                title="Contactos Totales"
                :value="numberWithCommas(dataContactoSize)"
                :icon="{
                  component: 'fas fa-users',
                  background: 'bg-gradient-success',
                  shape: 'rounded-circle',
                }"
              />
            </a>
          </div>
        </div>
<!--            <div class="col-lg-4 col-md-4 col-12">
              <a href="/contactos/lista-negra">
                <mini-statistics-card
                  title="Contactos Blacklist"
                  :value="numberWithCommas(dataBacklist.length)"
                  :icon="{
                    component: 'fas fa-user-slash',
                    background: 'bg-gradient-danger',
                    shape: 'rounded-circle',
                  }"
                />
              </a>
            </div>-->
        <div class="row mb-4">
          <div class="col">
            <div class="card bg-white border-0">
              <!-- Card body -->
              <div class="card-header border-1 d-flex align-items-center justify-content-between">
                <h6 class="card-title text-uppercase mb-0">
                  Resumen de últimos envíos
                </h6>
                <div
                  class="lh-1 text-center shadow icon icon-shape"
                  :class="[
                    typeof icon === 'object'
                      ? `${icon.background} ${icon.shape}`
                      : 'border-radius-md',
                    rowReverse ? 'me-2' : '',
                  ]"
                >
                  <i
                    class="text-lg opacity-10"
                    :class="
                      typeof icon === 'string' ? icon : icon.component
                    "
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
              <div class="card-body">
                <div class="row mt-4">
                  <div class="col">
                    <div class="table-container">
                      <table
                        class="table align-items-center mb-0 table-flush"
                      >
                        <thead>
                          <tr>
                            <th scope="col" class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 sortable">
                              Nombre
                            </th>
                            <th scope="col" class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 sortable">
                              Fecha
                            </th>
                            <th scope="col" class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 sortable">
                              Contactos
                            </th>
                            <th scope="col" class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 sortable">
                              Enviados
                            </th>
                            <th scope="col" class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 sortable">
                              Entregados
                            </th>
                            <th scope="col" class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 sortable">
                              No Entregados
                            </th>
                            <th scope="col" class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 sortable">
                              Canal
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="row in dataEnvios.length > 5
                              ? dataEnvios.slice(0, 5)
                              : dataEnvios"
                            :key="row.id"
                          >
                            <td>
                              <a
                                href="#!"
                                @click="descargarReportSingle(row, true)"
                                class="font-weight-bold mb-0 text-sm text-celcom-blue"
                              >
                                {{ row.sendingName }}
                              </a>
                            </td>
                            <td>
                              <small>{{ fechaFormato(row.sendingDate) }}</small>
                            </td>
                            <td>
                              <small>{{ row.sendingCount }}</small>
                            </td>
                            <td>
                              <small>{{ row.amountShipped }}</small>
                            </td>
                            <td>
                              <small>{{ row.sendingOk }}</small>
                            </td>
                            <td>
                              <small>{{ row.sendingNoOk }}</small>
                            </td>
                            <td>
                              <div v-if="getChannelName(row.channelId) === 'SMS'" class="badge badge-secondary">
                                SMS
                              </div>
                              <div v-else-if="getChannelName(row.channelId) === 'WHAATSAPP'" class="badge badge-success">
                                Whatsapp
                              </div>
                              <div v-else-if="getChannelName(row.channelId) === 'EMAIL'" class="badge badge-info">
                                Email
                              </div>
                              <div v-else class="badge badge-light border border-2 border-secondary text-secondary">
                                Canal no encontrado
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div class="d-flex justify-content-end mt-4">
                  <a
                    href="/reportes/reporte-mt"
                    class="btn btn-celcom-orange mb-0"
                  >
                    Ver más <i class="fas fa-arrow-circle-right text-pink"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
<!--          <div class="col-md-6">
            <div class="card bg-white border-0">
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col">
                    <h5
                      class="card-title text-uppercase text-muted mb-0 text-dark"
                    >
                      Resumen de listas creadas
                    </h5>
                  </div>
                  <div class="col-auto">
                    <div
                      class="text-center shadow icon icon-shape"
                      :class="[
                        typeof icon === 'object'
                          ? `${icon.background} ${icon.shape}`
                          : 'border-radius-md',
                        rowReverse ? 'me-2' : '',
                      ]"
                    >
                      <i
                        class="text-lg opacity-10"
                        :class="
                          typeof icon === 'string' ? icon : icon.component
                        "
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col">
                    <div class="table-container">
                      <table
                        class="table align-items-center mb-0 table-flush table-striped"
                      >
                        <thead class="thead-light">
                          <tr>
                            <th scope="col" class="min-width-250 sortable">
                              Nombre
                            </th>
                            <th scope="col" class="min-width-200 sortable">
                              Fecha Creación
                            </th>
                            <th scope="col" class="min-width-150 sortable">
                              Contactos
                            </th>
                            <th scope="col" class="min-width-125 sortable">
                              Válidos
                            </th>
                            <th scope="col" class="min-width-150 sortable">
                              Inválidos
                            </th>
                            <th scope="col" class="min-width-150 sortable">
                              Estado
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="row in dataContacto.length > 5
                              ? dataContacto.slice(0, 5)
                              : dataContacto"
                            :key="row.id"
                          >
                            <td class="min-width-250">
                              <div class="media align-items-center">
                                <div class="media-body">
                                  <a
                                    href="#!"
                                    @click="irDetail(row, true)"
                                    class="font-weight-600 mb-0 text-sm text-celcomblue"
                                  >
                                    {{ row.name }}
                                  </a>
                                </div>
                              </div>
                            </td>
                            <td class="min-width-200">
                              {{ fechaFormato(row.created) }}
                            </td>
                            <td class="min-width-150">
                              {{ row.validos + row.invalidos }}
                            </td>
                            <td class="min-width-125">
                              {{ row.validos }}
                            </td>
                            <td class="min-width-150">
                              {{ row.invalidos }}
                            </td>
                            <td class="min-width-150">
                              <badge
                                class="mr-4"
                                :type="row.active ? 'success' : 'danger'"
                              >
                                <span class="status">{{
                                  row.active ? "Activo" : "Inactivo"
                                }}</span>
                              </badge>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div class="d-flex justify-content-end mt-4">
                  <a
                    href="/contactos/lista-contactos"
                    class="btn font-weight-bold btn-plantilla"
                  >
                    Ver más <i class="fas fa-arrow-circle-right text-pink"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>-->
        </div>
      </div>
      <Loader ref="loader" />
    </div>
  </div>
</template>
<script>
import MiniStatisticsCard from "../../examples/Cards/MiniStatisticsCard.vue";
import {
  encryptAES,
  horaAhora,
  getUser,
  getUserSub,
} from "@/config/servicios/campana/util";
import {
  payment_products,
  blacklist_available,
  report_available,
} from "@/config/servicios/Dashboard/servicesDashboard";
import { listaContactosCamp } from "@/config/servicios/campana/contactos/servicesContactosCamp";
import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";
import CategoriesList from "@/components/Cards/CategoriesList.vue";
import {
  dataReportEnvioSms,
  dataReportWhat,
  dataReportEmail,
} from "@/config/servicios/campana/util";
import charts from "@/views/pages/Charts.vue";
import moment from "moment";
import Config from "@/config/servicios/campana/config";
import Loader from "@/components/Modal/Loader.vue";
import {available_channels} from "@/config/servicios/campana/envios/Reportes";
import Swal from "sweetalert2";
export default {
  name: "DashboardDefault",
  components: {
    CategoriesList,
    GradientLineChart,
    MiniStatisticsCard,
    Loader,
  },
  data() {
    return {
      dataChannels: [],
      icon: {
        component: "fas fa-clipboard-list",
        background: "bg-danger", // o cualquier clase de color específica de tu framework
        shape: "rounded-circle",
      },
      user: getUser(),
      getUserSub: getUserSub(),
      currentPage: 1,
      dataContacto: [0],
      dataBacklist: [0],
      dataEnvios: [],
      listaContactos: [],
      selectedRows: [],
      listaNombre: "",
      contactGroup: {},
      contactSingle: {},
      dataContactoSize: 0,
      dates: {
        inicioRep: "",
        finRep: horaAhora(),
      },
      fileSingle: [],
      reporteLoad: false,
      barChart: {
        chartData: {
          datasets: [
            {
              label: "SMS",
              data: [],
              borderWidth: 1,
            },
            {
              label: "Whatsapp",
              data: [],
              borderWidth: 1,
            },
            {
              label: "Email",
              data: [],
              borderWidth: 1,
            },
          ],
          labels: [
            "Lunes",
            "Martes",
            "Miércoles",
            "Jueves",
            "Viernes",
            "Sábado",
            "Domingo",
          ],
        },
      },
      productos: {},
      cargar: false,
    };
  },
  computed: {
    charts() {
      return charts;
    },
  },
  mounted() {
    this.obtenerReporteEnvio();//Obtengo datos de las graficas
    this.obtenerLista();
    this.obtenerListaBlack();
    this.obtenerProducts();
    this.obtenerChannels();
    if (this.$route.query.status === "true"){
      Swal.fire({
        title: 'Pago Realizado Correctamente',
        text: 'Se acredito a su saldo  '+this.$route.query.plan + ' ' + this.user.customer.country.currencyCode,
        html: `Se acredito a su saldo  <b>${this.$route.query.plan} ${this.user.customer.country.currencyCode}</b>`,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-celcom-blue',
        },
        type: 'suscess',
        icon: 'success',
      })
    }else if (this.$route.query.status === "false"){
      Swal.fire({
        title: 'Hubo un error en el Pago',
        text: 'Favor de intentar nuevamente',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-celcom-orange',
        },
        type: 'error',
        icon: 'error',
      })
    }
  },
  methods: {
    saldoDisponible(saldo) {
      if (saldo < 0){
        return "0";
      }else {
        return this.numberWithCommas(saldo);
      }
    },
    getChannelName(channelId) {
      // Buscar el objeto en dataChannels con el channelId correspondiente
      const channel = this.dataChannels.find(channel => channel.channelId === channelId);
      // Devolver el nombre del canal si se encuentra, de lo contrario devolver un mensaje de error
      return channel ? channel.channel_name : 'Canal no encontrado';
    },
    async obtenerChannels() {

      this.$refs.loader.showLoader();

      let self = this;
      let user = (getUserSub()!=null) ? getUserSub() :getUser();

      let js = {
        "time_expired":horaAhora(),
        "customer_user":encodeURIComponent(JSON.stringify(user)),
      }

      console.log(js);

      var body = new URLSearchParams(); body.append('TOKEN', (encryptAES(js)));

      await available_channels(body)
          .then(function (response) {
            console.log(response)

            if (response.data.response === "200") {
              self.dataChannels = response.data.data;
            } else {
              console.log("No se encontraron canales");
              Swal.fire({
                title: 'No se encontraron los canales',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-celcom-orange',
                },
                type: 'warning',
                icon: 'warning'
              })
            }

            self.$refs.loader.hideLoader();

          })
          .catch(function (error) {
            console.log("No se encontraron canales",error);
            Swal.fire({
              title: 'No se encontraron canales',
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-celcom-orange',
              },
              type: 'warning',
              icon: 'warning'
            })
            self.$refs.loader.hideLoader();
          });

    },
    async obtenerProducts() {
      //this.$loading.show();   //delay default is 300ms

      let self = this;
      let user = getUserSub() != null ? getUserSub() : getUser();

      let js = {
        time_expired: horaAhora(),
        customer: encodeURIComponent(JSON.stringify(user.customer)),
        email: "",
        password: "",
        userPhone: "",
        plan: "",
        transaction: "",
      };

      var body = new URLSearchParams();
      body.append("TOKEN", encryptAES(js));

      await payment_products(body)
        .then(function (response) {
          console.log(response);

          if (response.data.response === "200") {
            self.productos = response.data.data;
            console.log(response.data.data);
          } else {
            console.log("No se encontraron productos");
            alert("No se encontraron productos");
          }
        })
        .catch(function (error) {
          console.log("No se encontraron productos", error);
          alert("No se encontraron productos");
        });
    },
    numberWithCommas(x) {
      if (x === null || x === undefined) {
        return ""; // O podrías retornar "0" o cualquier otro valor por defecto adecuado
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    irCompra(payProductId) {
      this.$router.push({
        name: 'Comprar Creditos',
        params: {payProductId: payProductId.payProductId},
      });
    },

    async obtenerLista() {
      let self = this;
      let user = getUserSub() != null ? getUserSub() : getUser();

      let js = {
        time_expired: horaAhora(),
        customer: encodeURIComponent(JSON.stringify(user.customer)),
      };

      var body = new URLSearchParams();
      body.append("TOKEN", encryptAES(js));

      await listaContactosCamp(body)
        .then(function (response) {
          console.log(response);

          if (response.data.response === "200") {
            console.log(response.data.data);

            self.dataContacto =
              response.data.data.length <= 9
                ? response.data.data
                : response.data.data.slice(0, 8);

            self.dataContacto.forEach((element) => {
              self.dataContactoSize =  self.dataContactoSize + element.validos;
            });
          } else {
            console.log("No se encontró lista");
          }
        })
        .catch(function (error) {
          console.log("No se encontró lista", error);
        });
    },
    async obtenerListaBlack() {
      let self = this;

      let js = {
        time_expired: horaAhora(),
        customer_user: encodeURIComponent(JSON.stringify(self.user)),
      };

      var body = new URLSearchParams();
      body.append("TOKEN", encryptAES(js));

      await blacklist_available(body)
        .then(function (response) {
          console.log(response);

          if (response.data.response === "200") {
            console.log(response.data.data);

            self.dataBacklist = response.data.data;
          } else {
            console.log("No se encontró lista");
          }
        })
        .catch(function (error) {
          console.log("No se encontró lista", error);
        });
    },
    fechaFormato(unixTimestamp) {
      return moment(unixTimestamp).format("YYYY-MM-DD HH:mm:ss");
    },

    async obtenerReporteEnvio() {
      let self = this;
      let user = getUserSub() != null ? getUserSub() : getUser();
      // var arr=[];

      let js = {
        start_date: moment(
          moment(Date.now() - 7 * 24 * 3600 * 1000).format("YYYY-MM-DD h:mm:ss")
        )
          .valueOf()
          .toString(),
        end_date: moment(moment().format("YYYY-MM-DD h:mm:ss"))
          .valueOf()
          .toString(),
        global_report: false,
        time_expired: horaAhora(),
        customer_user: encodeURIComponent(JSON.stringify(user)),
      };

      console.log(js);

      var body = new URLSearchParams();
      body.append("TOKEN", encryptAES(js));

      try {
        const response = await report_available(body);
        console.log(response);
        if (response.data.response === "200") {
          console.log(response.data.data);

          this.dataEnvios = response.data.data.sort((a, b) =>
              a.idSending < b.idSending ? 1 : -1
          );
          localStorage.setItem('enviosreport', encryptAES(this.dataEnvios));

          const dataSms = await dataReportEnvioSms();
          const dataWhatsapp = await dataReportWhat();
          const dataEmail = await dataReportEmail();

          self.barChart.chartData.datasets[0].data = dataSms;
          self.barChart.chartData.datasets[1].data = dataWhatsapp;
          self.barChart.chartData.datasets[2].data = dataEmail;
          self.cargar = true;
        } else {
          console.log("No se encontraron registros envios para reporte home");
        }
      } catch (error) {
        console.log("Error al obtener datos para el gráfico:", error);
      }
    },
    descargarReportSingle(row) {
      this.$refs.loader.showLoader();

      /*let self = this;
      let user = (getUserSub()!=null) ? getUserSub() :getUser();*/

      let js = {
        start_date: moment(Date.now() - 7 * 24 * 3600 * 1000)
          .format("YYYY-MM-DD")
          .toString(),
        sendingsms_id: row.sendingSmsId.toString(),
        report_name: row.sendingName,
        time_expired: horaAhora(),
      };

      console.log(js);

      window.open(
        Config.url_service +
          Config.report_excel_report +
          encodeURIComponent(encryptAES(js))
      );
      this.$refs.loader.hideLoader();
    },
    irDetail(row) {
      localStorage.setItem("listcontact_init", JSON.stringify(row));
      this.$router.push({ name: "Contactos Lista" });
      //window.location.href="/contactos/lista-contactos"
    },
  },
};
</script>
<style scoped>
.table-container {
  max-height: 300px; /* Ajusta la altura máxima según tus necesidades */
  overflow-y: auto;
}
</style>
