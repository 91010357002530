<template>
  <div class="card">
    <div class="card-header pb-0">
      <h6 class="col-md-12 col-lg-5 col-xl">Reportes
        <small class="d-flex">Para generar tu reporte, se deben elegir las fechas de inicio y término.</small>
      </h6>
    </div>
    <div class="card-header border-1 d-md-flex justify-content-between align-items-center">
      <div class="d-flex flex-column flex-md-row">
<!--        <argon-input
          id="datetime-inicio"
          v-model="dates.inicioRep"
          type="datetime-local"
          class="multisteps-form__input me-md-2 m-md-0"
          placeholder="Selecciona una fecha"
        />
        <argon-input
          id="datetime-fin"
          v-model="dates.finRep"
          type="datetime-local"
          class="multisteps-form__input me-md-2 m-md-0"
          placeholder="Selecciona una fecha"
        />-->
        <date-picker
            class="multisteps-form__input me-md-2 m-md-0"
            @fecha-seleccionada="fechaInicio"
        />
        <date-picker
            class="multisteps-form__input me-md-2 m-md-0"
            @fecha-seleccionada="fechaFin"
        />
        <div class="col-md-4 mb-3 mb-md-0">
          <select
              :id="'selected2'"
              v-model="selectedChannel"
              class="multisteps-form__select form-control form-select"
          >
            <option disabled value="" selected>{{ "Seleccione canal" }}</option>
            <option
                v-for="channel in dataChannels" :key="channel.channelId" :value="channel"
            >
              {{ channel.channel_name}}
            </option>
          </select>
        </div>
      </div>
      <button
          class="btn btn-celcom-orange m-0"
          data-toggle="tooltip"
          data-original-title="Agregar Contacto"
          :disabled="!selectedChannel"
          @click.prevent="obtenerLista"
      >
      <i class="fas fa-filter me-2"></i>Filtrar
      </button>
    </div>

    <!-- Agrega un campo de entrada para el buscador -->
    <div class="pb-0 card-header mb-0">
      <input v-model="search" type="text" class="form-control mt-2" placeholder="Buscar...">
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <table class="table align-items-center mb-0">
          <thead>
          <tr>
            <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Nombre
            </th>
            <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              Fecha
            </th>
            <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Contactos
            </th>
            <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Enviados
            </th>
            <th
                v-if="selectedChannel.channel_name === 'EMAIL'"
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Abierto
            </th>
            <th
                v-if="selectedChannel.channel_name === 'EMAIL'"
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Click
            </th>
            <th
                v-if="selectedChannel.channel_name === 'EMAIL'"
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Rebotes
            </th>
            <th
                v-if="selectedChannel.channel_name === 'EMAIL'"
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Spam
            </th>
            <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Entregados
            </th>
            <th
                v-if="selectedChannel.channel_name === 'WHAATSAPP'"
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Leído
            </th>
            <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              No Entregados
            </th>
            <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Estado
            </th>
            <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Canal
            </th>
            <th class="text-secondary opacity-7"></th>
          </tr>
          </thead>
          <tbody>
          <!-- Utiliza v-for para recorrer el arreglo y generar filas -->
          <tr v-for="(row, index) in filteredData" :key="index">
            <td class="align-middle text-center text-sm">
              <div class="d-flex flex-column justify-content-center">
                <h6 class="mb-0 text-xs">{{ (row && row.sendingName) ? row.sendingName : 'Sin nombre' }}</h6>
              </div>
            </td>
            <td class="align-middle text-center text-sm">
              <div class="d-flex flex-column justify-content-center">
                <h6 class="mb-0 text-xs">{{fechaFormato(row.sendingDate)}}</h6>
              </div>
            </td>
            <td class="align-middle text-center text-sm">
              <div class="d-flex flex-column justify-content-center">
                <h6 class="mb-0 text-xs">{{row.sendingCount}}</h6>
              </div>
            </td>
            <td class="align-middle text-center text-sm">
              <div class="d-flex flex-column justify-content-center">
                <h6 class="mb-0 text-xs">{{ row.amountShipped }}</h6>
              </div>
            </td>
            <td v-if="selectedChannel.channel_name === 'EMAIL'" class="align-middle text-center text-sm">
              <div class="d-flex flex-column justify-content-center">
                <h6 class="mb-0 text-xs">{{ row.opened === null ? "0" : row.opened }}</h6>
              </div>
            </td>
            <td v-if="selectedChannel.channel_name === 'EMAIL'" class="align-middle text-center text-sm">
              <div class="d-flex flex-column justify-content-center">
                <h6 class="mb-0 text-xs">{{ row.clicked }}</h6>
              </div>
            </td>
            <td v-if="selectedChannel.channel_name === 'EMAIL'" class="align-middle text-center text-sm">
              <div class="d-flex flex-column justify-content-center">
                <h6 class="mb-0 text-xs">{{ sumaRebotes(row) }}</h6>
              </div>
            </td>
            <td v-if="selectedChannel.channel_name === 'EMAIL'" class="align-middle text-center text-sm">
              <div class="d-flex flex-column justify-content-center">
                <h6 class="mb-0 text-xs">{{ row.spam }}</h6>
              </div>
            </td>
            <td class="align-middle text-center text-sm">
              <div class="d-flex flex-column justify-content-center">
                <h6 class="mb-0 text-xs">{{ row.sendingOk }}</h6>
              </div>
            </td>
            <td v-if="selectedChannel.channel_name === 'WHAATSAPP'" class="align-middle text-center text-sm">
              <div class="d-flex flex-column justify-content-center">
                <h6 class="mb-0 text-xs">{{ row.read }}</h6>
              </div>
            </td>
            <td class="align-middle text-center text-sm">
              <div class="d-flex flex-column justify-content-center">
                <h6 class="mb-0 text-xs">{{ row.sendingNoOk }}</h6>
              </div>
            </td>
            <td class="align-middle text-center text-sm">
              <div class="d-flex flex-column justify-content-center">
                <h6 class="mb-0 text-xs">{{ row.sendingStatus }}</h6>
              </div>
            </td>
            <td class="align-middle text-center text-sm">
              <div v-if="getChannelName(row.channelId) === 'SMS'" class="badge badge-secondary">
                SMS
              </div>
              <div v-else-if="getChannelName(row.channelId) === 'WHAATSAPP'" class="badge badge-success">
                Whatsapp
              </div>
              <div v-else-if="getChannelName(row.channelId) === 'EMAIL'" class="badge badge-info">
                Email
              </div>
              <div v-else class="badge badge-light border border-2 border-secondary text-secondary">
                Canal no encontrado
              </div>
            </td>
            <td class="align-middle text-center text-sm">
              <button
                  class="btn btn-success mb-0"
                  data-toggle="tooltip"
                  data-original-title="Edit user"
                  @click.prevent="descargarReportSingle(row)"
              >
                <i class="fas fa-file-excel"></i>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div
          v-if="filteredData.length === 0"
          class="mt-3 mb-3">
        <p class="text-center text-muted">No hay datos disponibles.</p>
      </div>
      <div class="d-flex justify-content-end">
        <button
            v-show="reporteLoad && filteredData.length > 0"
            class="btn fw-bolder btn-celcom-blue mt-3"
            data-toggle="tooltip"
            data-original-title="Agregar Contacto"
            @click.prevent="descargarReport(tableData)"
        >
          <i class="fas fa-download"></i> Descargar Reportes
        </button>
      </div>
    </div>
    <Loader ref="loader"/>
  </div>
</template>

<script>
import * as XLSX from "xlsx";
import {report_available, available_channels} from "@/config/servicios/campana/envios/Reportes";
import {encryptAES, horaAhora,horaAhora2, fechaFormato, getUser, getUserSub} from "@/config/servicios/campana/util";
import Swal from "sweetalert2";
import moment from "moment";
import Loader from "@/components/Modal/Loader.vue";
import Config from "@/config/servicios/campana/config";
import DatePicker from "@/views/envios/Reportes/components/DatePicker.vue";
export default {
  name: "ReportesMTTable",
  components: {
    Loader,
    DatePicker
  },
  props: {

  },
  data() {
    return {
      selectedChannel: "",
      search: "", // Variable para almacenar la entrada del buscador
      currentPage: 1,
      tableData: [],
      dataChannels: [],
      listaContactos: [],
      selectedRows: [],
      listaNombre: "",
      contactGroup: {},
      contactSingle: {},
      dates: {
        inicioRep: horaAhora2(),
        finRep: horaAhora2()
      },
      fileSingle: [],
      reporteLoad: false,
      keySearch:""
    };
  },
  computed: {
    // Utiliza una propiedad computada para filtrar los datos según la búsqueda
    filteredData() {
      if (!Array.isArray(this.tableData)) {
        return [];
      }
      return this.tableData.filter(report => {
        const name = report.sendingName ?? '';

        return (
            name.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
  },
  mounted() {
    this.obtenerChannels();
  },
  methods: {
    fechaInicio(fecha) {
      this.dates.inicioRep = fecha;
    },
    fechaFin(fecha) {
      this.dates.finRep = fecha;
    },
    sumaRebotes(row) {
      return parseInt(row.hard_BOUNCE) + parseInt(row.soft_BOUNCE);
    },
    getChannelName(channelId) {
      // Buscar el objeto en dataChannels con el channelId correspondiente
      const channel = this.dataChannels.find(channel => channel.channelId === channelId);
      // Devolver el nombre del canal si se encuentra, de lo contrario devolver un mensaje de error
      return channel ? channel.channel_name : 'Canal no encontrado';
    },
    fechaFormato(fecha) {
      return fechaFormato(fecha);
    },
    async obtenerLista() {

      this.$refs.loader.showLoader();

      let self = this;
      let user = (getUserSub()!=null) ? getUserSub() :getUser();

      let js = {
        "start_date":moment(this.dates.inicioRep).valueOf().toString(),
        "end_date":moment(this.dates.finRep).valueOf().toString(),
        "global_report":false,
        "time_expired":horaAhora(),
        "channel_id":this.selectedChannel.channelId,
        "customer_user":encodeURIComponent(JSON.stringify(user)),
      }

      console.log(js);

      var body = new URLSearchParams(); body.append('TOKEN', (encryptAES(js)));

      await report_available(body)
          .then(function (response) {
            console.log(response)

            if (response.data.response === "200") {
              console.log(response.data.data);


              if(response.data.data.length>0){
                self.reporteLoad = true;
              }
              self.tableData = response.data.data.sort((a, b) => (a.idSending < b.idSending) ? 1 : -1);

            } else {
              console.log("No se encontraron registros");
              Swal.fire({
                title: 'No se encontraron registros',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-celcom-orange',
                },
                type: 'warning',
                icon: 'warning'
              })
            }

            self.$refs.loader.hideLoader();

          })
          .catch(function (error) {
            console.log("No se encontraron registros",error);
            Swal.fire({
              title: 'No se encontraron registros',
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-celcom-orange',
              },
              type: 'warning',
              icon: 'warning'
            })
            self.$refs.loader.hideLoader();
          });

    },
    async obtenerChannels() {

      this.$refs.loader.showLoader();

      let self = this;
      let user = (getUserSub()!=null) ? getUserSub() :getUser();

      let js = {
        "time_expired":horaAhora(),
        "customer_user":encodeURIComponent(JSON.stringify(user)),
      }

      console.log(js);

      var body = new URLSearchParams(); body.append('TOKEN', (encryptAES(js)));

      await available_channels(body)
          .then(function (response) {
            console.log(response)

            if (response.data.response === "200") {
              self.dataChannels = response.data.data;
            } else {
              console.log("No se encontraron canales");
              Swal.fire({
                title: 'No se encontraron los canales',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-celcom-orange',
                },
                type: 'warning',
                icon: 'warning'
              })
            }

            self.$refs.loader.hideLoader();

          })
          .catch(function (error) {
            console.log("No se encontraron canales",error);
            Swal.fire({
              title: 'No se encontraron canales',
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-celcom-orange',
              },
              type: 'warning',
              icon: 'warning'
            })
            self.$refs.loader.hideLoader();
          });

    },
    descargarReportSingle(row) {

      this.$refs.loader.showLoader();

      let self = this;
      //let user = (getUserSub()!=null) ? getUserSub() :getUser();

      let js = {
        "start_date":moment(this.dates.inicioRep).format('YYYY-MM-DD').toString(),
        "sendingsms_id":row.sendingSmsId.toString(),
        "report_name":row.sendingName,
        "time_expired":horaAhora(),
      }

      console.log(js);

      window.open(Config.url_service+Config.report_excel_report + encodeURIComponent(encryptAES(js)))

      self.$refs.loader.hideLoader();

    },
    descargarReport() {

      var tbl = this.tableData;
      var ws = XLSX.utils.json_to_sheet(tbl);

      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Reporte MT");

      XLSX.writeFile(wb, 'ReporteMT.xlsx');

    }
  },
};
</script>

<style scoped>
.btn-plantilla{
  color: #fff;
  background-color: #e64477;
  border-color: #e64477;
}
/* Agrega estilos según tus necesidades */
.icono-rojo {
  color: red;
}
.modal {
  display: none;
}

.modal.is-active {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  background: none;
  border: none;
}
.buttons {
  margin: 8px;
}
.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  position: absolute;
  bottom: 100%; /* Coloca el tooltip arriba del contenido */
  left: 50%;
  transform: translateX(-50%);
  padding: 5px;
  background-color: #333;
  color: #fff;
  border-radius: 3px;
  font-size: 12px;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none; /* Evita que el tooltip sea clickeable */
}

.tooltip-container:hover .tooltip {
  opacity: 1;
}
</style>